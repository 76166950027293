import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import { action } from 'mobx';
import { Component } from 'react';
import { ph_emptyImageURL } from '../../../../../0-common/utils/DefaultImage';
import { emitter, EVENTS } from '../../../../../0-common/utils/EventEmitter';
import windowUtil from '../../../../utils/WindowUtil';
class CardViewBaseTemplate extends Component {

    constructor(props){
        super(props);
        this.shopStore = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
        this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
        this.navigationStore = props.storeFactory.NavigationStore;
        this.moduleStore = props.storeFactory.ModuleStore;
    }
    
    get TemplateStyle(){
        return this.props.templateStyle;
    }

    get TotalPageCount(){
        return this.props.pageCount;
    }

    get ActivePageIndex(){
		return this.navigationStore.getActviePage();//Pagination
	}

    get isAddInProgress(){
        return  this.orderStore.isAddInProgress;
    }

    get isAShop(){
        const webShopCategory = this.shopStore.ShopCategory;
        return (webShopCategory === 'StandardWebShop');
    }

    get isAWebsite(){
        const webShopCategory = this.shopStore.ShopCategory;
        return (webShopCategory === 'StandardWebSite');
    }

    get Currency(){
        return this.props.storeFactory.CurrencyStore.Currency;
    }

    getItemCategoryName(itemCategory){
        let moduleType = "";
        switch (this.props.pageType) {
            case "PRODUCT":
                moduleType = "PRODUCTS";
                break;
            case "COLLECTION":
                moduleType = "COLLECTIONS";
                break;
        }
        const categoryDictionary = this.moduleStore.getModuleCategories(moduleType);
        const category = _find(categoryDictionary, ['key', itemCategory]);
	    return (category ? category.text : "$$$");
    }

    getItemImage(item){
        let profileImageID = null;

        const imageId = item.DATA.ProfileImage;
        if(imageId && imageId !== ""){
            profileImageID = item.REFERENCE_IMAGES[imageId].SourceID;
        }
        if(profileImageID){
            return "https://ucarecdn.com/"+profileImageID+"/-/format/auto/-/quality/smart/";
        } else {
            return ph_emptyImageURL;
        }
    }

    getItemURL(itemID, itemCategory){
        let itemInfo = "";
        let itemURL = "";
        let pageType = "";
        switch (this.props.pageType) {
            case "PRODUCT":
                pageType = "ProductPage";
                break;
            case "COLLECTION":
                pageType = "CollectionPage";
                break;
        }
        const pageRef = _find(this.shopStore.Pages, {"CategorySourceID" : pageType});
        const contextualParam = this.navigationStore.getContextualParam("SHOP");
        const shopViewMode = contextualParam.shopMode;
	    if(shopViewMode === "preview"){
            const shopCatgeory = this.shopStore.getCurrentItem().DATA.Category;
            const shopID = this.shopStore.getCurrentItem().DATA.uid;
            itemInfo = `${shopCatgeory}/${shopID}/${pageRef.uid}/${itemCategory}/${itemID}`;
        } else { // shopViewMode === "live"
            itemInfo = `${pageRef.uid}/${itemCategory}/${itemID}`;
        }

        if(shopViewMode === "preview"){
            itemURL = `https://${windowUtil.HostName}${(windowUtil.Port ? ':' + windowUtil.Port : '')}/p/WEBSHOPS/shop/${itemInfo}`;
        } else { // shopViewMode === "live"
            const contextualParam = this.navigationStore.getContextualParam("SHOP")
            if(contextualParam && contextualParam.shopLink){
                const shopLink = contextualParam.shopLink;
                itemURL = `https://${windowUtil.HostName}${(windowUtil.Port ? ':' + windowUtil.Port : '')}/s/${shopLink}/shop/${itemInfo}`;
            } else {
                itemURL = `https://${windowUtil.HostName}${(windowUtil.Port ? ':' + windowUtil.Port : '')}/shop/${itemInfo}`;
            }
        }
		return itemURL;
    }

    isProductAddedInCart(productID){
        const productOptionAddedInCart = this.orderStore.getProductOptionAddedInCartFor(productID);
        if(_isEmpty(productOptionAddedInCart)){
            return false;
        } else {
            return true;
        }
    }

    @action
    addToCart = (product, productOption, productOptionText, event) =>{
        if(!this.isAddInProgress){
            //Added for capturing G4 Analytics
            const categoryDictionary = this.moduleStore.getModuleCategories("PRODUCTS");
            const categoryNameList = _compact(_map(product.DATA.Categories, (itemCategoryID) => {
                const category = _find(categoryDictionary, ['key', itemCategoryID]);
                return (category ? category.text : null);
            }));

            const productInfo = {
                productId: product.DATA.uid,
                productOption,
                productOptionText,
                productName: product.DATA.Name,
                productRetailPrice: (product.DATA.RetailPrice || product.DATA.DiscountedPrice),
                productDiscountedPrice: product.DATA.DiscountedPrice,
                productBrand: product.DATA.Brand,
                productCategoryList: categoryNameList,
                context: "All Products Page",
            }
            emitter.emit(EVENTS.CART.ADD, productInfo);
        }
            
		event.stopPropagation();
    }

    @action
    showShoppingCart(event){
		emitter.emit(EVENTS.CART.OPEN);
        event.stopPropagation();
    }

    @action
    handleOpenItemView = (itemID, itemCategory, event) => {
        let pageType = "";
        let subView = "";
        switch (this.props.pageType) {
            case "PRODUCT":
                pageType = "ProductPage";
                subView = this.navigationStore.SUBVIEW.SHOP.PRODUCT.OPEN;
                break;
            case "COLLECTION":
                pageType = "CollectionPage";
                subView = this.navigationStore.SUBVIEW.SHOP.COLLECTION.OPEN;
                break;
        }
        const pageRef = _find(this.shopStore.Pages, {"CategorySourceID" : pageType});
        this.shopStore.ActivePage = pageRef.uid;
        this.navigationStore.changeView(this.navigationStore.VIEW.SHOP, subView);

        const contextualParam = this.navigationStore.getContextualParam("SHOP");
        contextualParam.itemCategory = itemCategory;
        contextualParam.itemID = itemID;
        this.navigationStore.setContextualParam("SHOP", contextualParam);

        switch (this.props.pageType) {
            case "PRODUCT":
                //localStorage.setItem('productListURL_Back', JSON.stringify(window.location.href));
                localStorage.setItem('productPageID', JSON.stringify(this.shopStore.ActivePage));
                break;
            case "COLLECTION":
                localStorage.setItem('collectionPageID', JSON.stringify(this.shopStore.ActivePage));
                break;
        }
        event.preventDefault();
    }

    @action
    handlePageNavAction = (e, {activePage}) => {
        emitter.emit(EVENTS.PAGE.PAGINATE+this.props.eventContext, activePage);
    }

    //Need to be overridden
    renderLoadingCardView(){
        return null;
    }

    //Need to be overridden
    renderCardView(){
        return null;
    }

    render() {
        console.log("R. CardViewBaseTemplate");
        if(this.props.isLoading)
            return this.renderLoadingCardView();
        else
            return this.renderCardView();
    }
}

export default CardViewBaseTemplate;