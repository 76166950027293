import _times from 'lodash/times';
import _map from 'lodash/map';
import _find from 'lodash/find';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { ChevronLeft, ChevronRight, Eye, Plus, ShoppingCart } from 'react-feather';
import { Container, Header, Image, Placeholder, Segment } from 'semantic-ui-react';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import SectionBaseTemplate from './base/SectionBaseTemplate';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';


const arrowSize = "100px";


const SliderNextArrow = function (props) {
    const { className, style, onClick } = props;
    const Style = props.templateStyle;
    return (
        <Style.Slider.NextArrow>
            <ChevronRight size={arrowSize} strokeWidth="0.25px" onClick={onClick} />
        </Style.Slider.NextArrow>

    );
}

const SliderPrevArrow = function (props) {
    const { className, style, onClick } = props;
    const Style = props.templateStyle;
    return (
        <Style.Slider.PrevArrow>
            <ChevronLeft size={arrowSize} strokeWidth="0.25px" onClick={onClick} />
        </Style.Slider.PrevArrow>
    );
}

@inject("storeFactory")
@observer
class SectionTemplate3 extends SectionBaseTemplate {

    carousalSettings = {
        dots: false,
        infinite: true,
        pauseOnHover: true,
        speed: 750,
        autoplay: true,
        autoplaySpeed: 2500,
        slidesToShow: 4,
        mobile_slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ],
        cssEase: "linear",
        nextArrow: <SliderNextArrow templateStyle={this.TemplateStyle} />,
        prevArrow: <SliderPrevArrow templateStyle={this.TemplateStyle} />
    };
    
    //@Overridden
    get ShouldLoadProductsInCollection() {
        return true;
    }

    buildShoppingIcon(item){
        if(this.isAShop){
            const Style = this.TemplateStyle;
            const itemID = item.DATA.uid;
            const inStock = item.DATA.InStock;
            const itemDiscountedPrice = item.DATA.DiscountedPrice;
            const itemRetailPrice = item.DATA.RetailPrice;

            if(inStock && itemDiscountedPrice){
                const isItemAddedInCart = this.isProductAddedInCart(itemID);
                if(isItemAddedInCart){
                    return(
                        <Style.ProductActionIcon bordered size="large" onClick={this.showShoppingCart}>
                            <ShoppingCart size="18px" />
                        </Style.ProductActionIcon>
                    );
                } else {

                    let productOption = 'ONE_SIZE';
                    let productOptionText = '';
                    const optionPredicate = item.DATA.CATEGORY_MASTERS_Size;
                    if(optionPredicate && item.DATA[optionPredicate]){
                        const firstOptionValue = item.DATA[optionPredicate][0];
                        const optionValueEntry = _find(item.OPTIONS[optionPredicate], ["key", firstOptionValue]);
                        if(optionValueEntry){
                            productOption = optionValueEntry.source;
                            productOptionText = optionValueEntry.text;
                        }
                    }

                    return(
                        <Style.ProductActionIcon bordered size="large" onClick={this.addToCart.bind(this, item, productOption, productOptionText)} $isAddInProgress={this.isAddInProgress}>
                            <Plus size="18px" />
                        </Style.ProductActionIcon>
                    );
                }
            } else {
                return(
                    <Style.ProductActionIcon bordered size="large" $isDisabled={true} onClick={this.stopPropagation}>
                        <Plus size="18px" />
                    </Style.ProductActionIcon>
                );
            }
        } else {
            return null;
        }
    }

    //@Overridden
    renderLoadingView(isMobileView) {
        const Style = this.TemplateStyle;
        this.carousalSettings.infinite = false;
        return (
            <Container style={{ margin: "0" }} fluid>
                <Segment textAlign="center" basic>
                    <Style.CollectionNameHeader textAlign="center">
                        <Placeholder style={{ margin: "auto" }}>
                            <Placeholder.Header>
                                <Placeholder.Line length='very long' />
                            </Placeholder.Header>
                        </Placeholder>
                        <Header.Subheader>
                            <Placeholder style={{ margin: "3rem auto 0" }}>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Header.Subheader>
                    </Style.CollectionNameHeader>
                </Segment>
                <Style.Slider.SliderRef {...this.carousalSettings}>
                    {
                        _times( (isMobileView ? 1 : 4), (counter) => {
                            return (
                                <Segment basic key={counter}>
                                    <Style.ProductImageDiv>
                                        <Placeholder style={{ height: "100%" }}>
                                            <Placeholder.Image square />
                                        </Placeholder>
                                    </Style.ProductImageDiv>
                                    <Style.ProductNameHeader textAlign="center">
                                        <Placeholder>
                                            <Placeholder.Header>
                                                <Placeholder.Line length='long' />
                                            </Placeholder.Header>
                                        </Placeholder>
                                        <Style.ProductCategorySubHeader>
                                            <Placeholder>
                                                <Placeholder.Header>
                                                    <Placeholder.Line length='medium' />
                                                </Placeholder.Header>
                                            </Placeholder>
                                        </Style.ProductCategorySubHeader>
                                        <Style.ProductPriceSubHeader>
                                            <Placeholder>
                                                <Placeholder.Header>
                                                    <Placeholder.Line length='short' />
                                                    <Placeholder.Line length='short' />
                                                </Placeholder.Header>
                                            </Placeholder>
                                        </Style.ProductPriceSubHeader>
                                    </Style.ProductNameHeader>
                                </Segment>
                            );
                        })
                    }
                </Style.Slider.SliderRef>
            </Container>
        );
    }

    //@Overridden
    renderSectionHeader(){
        const Style = this.TemplateStyle;
        let collection = null;
        if (this.Collections.length > 0)
            collection = this.Collections[0];
        const collectionName = collection ? collection.DATA.Name : '';
        const collectionDesc = collection ? parse(collection.DATA.Description_RT || collection.DATA.Description || "") : '';
        return(
            <Segment textAlign="center" basic>
                <Style.CollectionNameHeader textAlign="center">
                    {collectionName}
                    <Header.Subheader className="ql-editor">
                        {collectionDesc}
                    </Header.Subheader>
                </Style.CollectionNameHeader>
            </Segment>
        );
    }

    //@Overridden
    renderMobileView() {
        return this.renderView(true);
    }

    //@Overridden
    renderView(isMobile) {
        const Style = this.TemplateStyle;

        this.carousalSettings.dots = false;
        this.carousalSettings.infinite = true;
        // If slides are less than ${this.carousalSettings.slidesToShow}, cloned slide (used for infinite sliding) starts showing up on screen.
        if (this.ProductsInCollection.length < this.carousalSettings.slidesToShow) {
            this.carousalSettings.infinite = false;
        }
        if (isMobile && (this.ProductsInCollection.length < this.carousalSettings.mobile_slidesToShow)) {
            this.carousalSettings.infinite = false;
        }
        
        return (
            <Container style={{ margin: "0" }} fluid>
                <Style.Slider.SliderRef {...this.carousalSettings}>
                    {
                        _map(this.ProductsInCollection, (product, index) => {
                            const productImageURL = this.getProductImage(product);
                            const productID = product.DATA.uid;
                            const productName = product.DATA.Name;
                            const productCategory = product.DATA.CategorySourceID;
                            const productCategoryName = product.DATA.CategoryName;

                            let productOption = 'ONE_SIZE';
                            let productOptionText = '';
                            const optionPredicate = product.DATA.CATEGORY_MASTERS_Size;
                            if (optionPredicate && product.DATA[optionPredicate]) {
                                const firstOptionValue = product.DATA[optionPredicate][0];
                                const optionValueEntry = _find(product.OPTIONS[optionPredicate], ["key", firstOptionValue]);
                                if (optionValueEntry) {
                                    productOption = optionValueEntry.source;
                                    productOptionText = optionValueEntry.text;
                                }
                            }

                            const productDiscountedPrice = product.DATA.DiscountedPrice;
                            const productRetailPrice = product.DATA.RetailPrice;
                            const productURL = this.getItemURL(productID, productCategory, "PRODUCT");

                            return (
                                <Segment basic key={productID}>
                                    <Style.ProductImageDiv>
                                        <Image src={productImageURL} fluid />
                                        <Style.ProductActionSegment basic onClick={this.handleOpenProductView.bind(this, productID, productCategory)}>
                                            <Style.ProductActionItemInfo>
                                                <Style.ProductNameHeader textAlign="center">
                                                    {productName}
                                                    <Style.ProductCategorySubHeader $margin="1rem 0 0">
                                                        {productCategoryName}
                                                    </Style.ProductCategorySubHeader>
                                                    <Style.ProductPriceSubHeader $margin="1rem 0 0">
                                                        {
                                                            productDiscountedPrice
                                                            &&
                                                            <span className="price discountedPrice">{this.Currency.format(productDiscountedPrice)}</span>
                                                        }
                                                    </Style.ProductPriceSubHeader>
                                                </Style.ProductNameHeader>
                                            </Style.ProductActionItemInfo>
                                            <Style.ProductActionIconInfo>
                                                { this.buildShoppingIcon(product) }
                                                <Style.ProductActionIcon bordered size="large" >
                                                    <Eye size="18px" />
                                                </Style.ProductActionIcon>
                                            </Style.ProductActionIconInfo>
                                        </Style.ProductActionSegment>
                                    </Style.ProductImageDiv>
                                    <Style.ProductNameHeader textAlign="center" onClick={this.handleOpenProductView.bind(this, productID, productCategory)}>
                                        <a href={productURL}>{productName}</a>
                                        <Style.ProductCategorySubHeader $margin="0.285rem 0 0">
                                            {productCategoryName}
                                        </Style.ProductCategorySubHeader>
                                        <Style.ProductPriceSubHeader $margin="0.285rem 0 0">
                                            {
                                                productRetailPrice
                                                &&
                                                <span className="price retailPrice">{this.Currency.format(productRetailPrice)}</span>
                                            }
                                            {
                                                productDiscountedPrice
                                                &&
                                                <span className="price discountedPrice">{this.Currency.format(productDiscountedPrice)}</span>
                                            }
                                        </Style.ProductPriceSubHeader>
                                    </Style.ProductNameHeader>
                                </Segment>
                            );
                        })
                    }
                </Style.Slider.SliderRef>
            </Container>
        );
    }
}

export default SectionTemplate3;