import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import { action, runInAction } from 'mobx';
import React, { Component } from 'react';
import { Container, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { ph_emptyImageURL } from '../../../../../0-common/utils/DefaultImage';
import { emitter, EVENTS } from '../../../../../0-common/utils/EventEmitter';
import { Media } from '../../../../utils/Responsive';
import windowUtil from '../../../../utils/WindowUtil';

const StyledSectionSpacer = styled.div`
    width: 100%;
    height: 2.5rem;
    background: transparent;
`;

const StyledSectionContainer = styled(Container)`
    position: relative;
`;

const StyledSectionBackgroundImage = styled.div`
    position: absolute;
    inset: 0;
    ${props => props.$bgImageURL && `
        background-image: url(${props.$bgImageURL});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        opacity: 0.8;

        &::after{
            content: "";
            position: absolute;
            inset: 0;
            box-sizing: border-box;
            background: ${props.$blendMode === 'darkmode' ? 'rgba(0,0,0,0.6)' : 'rgba(255,255,255,0.6)'};
        }
    `}
`;

const StyledSectionHeader = styled(Header)`
    &&&{
        font-family: var(--primary-font);
        font-size: 2rem;
        color : var(--secondary-color-dark);
        filter: brightness(0.5);
        font-weight: 400;
        text-align: center;
        margin: 1rem;
    }
`;

const _DEFAULT_CONTENT_BLEND_MODE = "lightmode";

/**
 * {props.templateType} 
 *  - 'TEMPLATE' : SectionTemplates are initialized without any HOC, hence the rendering is happening via Live Shop
 *  - 'WRAPPER'  : SectionTemplates are initialized with HOC - SectionTemplateWrapper, hence the rendering is happening via Shop Configuration View (App Side)
 *                 checkout (SectionTemplateWrapper)
 */
class SectionBaseTemplate extends Component {

    constructor(props){
        super(props);
        if(props.templateType === "TEMPLATE"){//The other templateType is "WRAPPER" (checkout 'SectionTemplateWrapper')
            this.shopStore = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
            const X_SUBJECT_TYPE = {
                "uid":props.section.DATA.uid,
                "Type":"COLLECTIONS",
                "Category":"",
                "PREDICATE_TYPE":"CollectionsInSection",
                "FIND_WITHIN":true,
                "PAGE_SIZE":-1,
                "FIND_WITH_FILTERS":true,
                "REQUESTED_PAGE":1,
                "APP_OWNER": this.shopStore.OwnerInfo.SourceID,
                "ShopID": this.shopStore.ShopID
            };
            this.itemStore = props.storeFactory.fetchStore("WEBSECTIONS", X_SUBJECT_TYPE);
            this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
            this.navigationStore = props.storeFactory.NavigationStore;
            this.moduleStore = props.storeFactory.ModuleStore;
        }
    }

    get ShouldLoadProductsInCollection(){
		return false;
	}

    get TemplateStyle(){
        return this.props.templateStyle;
    }

    get SellerID(){
        return this.shopStore.OwnerInfo.SourceID;
    }

    get ShopID(){
        return this.shopStore.ShopID;
    }

    get Collections(){
        return this.itemStore.ItemList;
    }

    get SectionID(){
        return this.props.section.DATA.uid;
    }

    get SectionName(){
        return this.props.section.DATA.Name;
    }

    get SectionBackgroundImage(){
        let profileImageID = null;
        const imageId = this.props.section.DATA.ProfileImage;
        if(imageId && imageId !== ""){
            profileImageID = this.props.section.REFERENCE_IMAGES[imageId].SourceID;
        }
        if(profileImageID){
            return "https://ucarecdn.com/"+profileImageID+"/-/format/auto/-/quality/smart/";
        } else {
            return null;
        }
    }

    get ContentBlendMode(){
        const contentBlendModeOptions = this.props.section.OPTIONS["ContentBlendMode"];
        let contentBlendMode = _find(contentBlendModeOptions, ["source", _DEFAULT_CONTENT_BLEND_MODE]);
        const contentBlendModeId = this.props.section.DATA.ContentBlendMode ? this.props.section.DATA.ContentBlendMode[0] : null;
        if(contentBlendModeId){
            const contentBlendModeOption = _find(contentBlendModeOptions, ["value", contentBlendModeId]);
            if(contentBlendModeOption){
                contentBlendMode = contentBlendModeOption;
            }
        }

        return contentBlendMode;
    }

    get ProductsInCollection(){
        let collection = null;
        if(this.Collections.length > 0)
            collection = this.Collections[0];
        
        if(collection){
            const X_SUBJECT_TYPE = {
                "uid":collection.DATA.uid,
                "Type":"PRODUCTS",
                "Category":"",
                "PREDICATE_TYPE":"ProductsInCollection",
                "FIND_WITHIN":true,
                "PAGE_SIZE":-1,
                "FIND_WITH_FILTERS":false,
                "REQUESTED_PAGE":1,
                "APP_OWNER": this.shopStore.OwnerInfo.SourceID,
                "ShopID": this.shopStore.ShopID
            };
            const shopCollectionStore = this.props.storeFactory.fetchStore("SHOPCOLLECTIONS", X_SUBJECT_TYPE);
            return shopCollectionStore.ItemList;
        }else{
            return [];
        }
    }

    get isLoading(){
        return this.itemStore.isLoading;
    }

    get isAddInProgress(){
        return  this.orderStore.isAddInProgress;
    }

    get isAShop(){
        const webShopCategory = this.shopStore.ShopCategory;
        return (webShopCategory === 'StandardWebShop');
    }

    get Currency(){
        return this.props.storeFactory.CurrencyStore.Currency;
    }

    isProductAddedInCart(productID){
        const productOptionAddedInCart = this.orderStore.getProductOptionAddedInCartFor(productID);
        if(_isEmpty(productOptionAddedInCart)){
            return false;
        } else {
            return true;
        }
    }

    getProductImage(product){
        let profileImageID = null;

        const imageId = product.DATA.ProfileImage;
        if(imageId && imageId !== ""){
            profileImageID = product.REFERENCE_IMAGES[imageId].SourceID;
        }
        if(profileImageID){
            return "https://ucarecdn.com/"+profileImageID+"/-/format/auto/-/quality/smart/";
        } else {
            return ph_emptyImageURL;
        }
    }

    getCollectionProfileImage(collection, index = "FIRST"){
        let profileImageID = null;

        const imageId = collection.DATA.ProfileImage;
        if(imageId && imageId !== ""){
            profileImageID = collection.REFERENCE_IMAGES[imageId].SourceID;
        }
        if(profileImageID){
            return "https://ucarecdn.com/"+profileImageID+"/-/format/auto/-/quality/smart/";
        } else {
            return ph_emptyImageURL;
        }
	}

    getItemURL(itemID, itemCategory, itemType){
        let itemInfo = "";
        let itemURL = "";
        let pageType = "";
        switch (itemType) {
            case "PRODUCT":
                pageType = "ProductPage";
                break;
            case "COLLECTION":
                pageType = "CollectionPage";
                break;
        }
        const pageRef = _find(this.shopStore.Pages, {"CategorySourceID" : pageType});
        const contextualParam = this.navigationStore.getContextualParam("SHOP");
        const shopViewMode = contextualParam.shopMode;
	    if(shopViewMode === "preview"){
            const shopCatgeory = this.shopStore.getCurrentItem().DATA.Category;
            const shopID = this.shopStore.getCurrentItem().DATA.uid;
            itemInfo = `${shopCatgeory}/${shopID}/${pageRef.uid}/${itemCategory}/${itemID}`;
        } else { // shopViewMode === "live"
            itemInfo = `${pageRef.uid}/${itemCategory}/${itemID}`;
        }

        if(shopViewMode === "preview"){
            itemURL = `https://${windowUtil.HostName}${(windowUtil.Port ? ':' + windowUtil.Port : '')}/p/WEBSHOPS/shop/${itemInfo}`;
        } else { // shopViewMode === "live"
            const contextualParam = this.navigationStore.getContextualParam("SHOP")
            if(contextualParam && contextualParam.shopLink){
                const shopLink = contextualParam.shopLink;
                itemURL = `https://${windowUtil.HostName}${(windowUtil.Port ? ':' + windowUtil.Port : '')}/s/${shopLink}/shop/${itemInfo}`;
            } else {
                itemURL = `https://${windowUtil.HostName}${(windowUtil.Port ? ':' + windowUtil.Port : '')}/shop/${itemInfo}`;
            }
        }

		return itemURL;
    }


    @action
    handleOpenCollectionView = (collectionID, collectionCategory) => {
        const sectionID = this.SectionID;
        const pageRef = _find(this.shopStore.Pages, {"CategorySourceID" : "CollectionPage"});
        this.shopStore.ActivePage = pageRef.uid;
        this.navigationStore.changeView(this.navigationStore.VIEW.SHOP, this.navigationStore.SUBVIEW.SHOP.COLLECTION.OPEN_VIA_SECTION);

        const contextualParam = this.navigationStore.getContextualParam("SHOP");
        contextualParam.itemCategory = `${collectionCategory}-SEP-${sectionID}`;
        contextualParam.itemID = collectionID;
        this.navigationStore.setContextualParam("SHOP", contextualParam);
    }

    @action
    handleOpenProductView = (productID, productCategory) => {
        const pageRef = _find(this.shopStore.Pages, {"CategorySourceID" : "ProductPage"});
        this.shopStore.ActivePage = pageRef.uid;
        this.navigationStore.changeView(this.navigationStore.VIEW.SHOP, this.navigationStore.SUBVIEW.SHOP.PRODUCT.OPEN);

        const contextualParam = this.navigationStore.getContextualParam("SHOP");
        contextualParam.itemCategory = productCategory;
        contextualParam.itemID = productID;
        this.navigationStore.setContextualParam("SHOP", contextualParam);

        //localStorage.setItem('productListURL_Back', JSON.stringify(window.location.href));
        localStorage.setItem('productPageID', JSON.stringify(this.shopStore.ActivePage));
    }


    addToCart = (product, productOption, productOptionText, event) => {

        //Added for capturing G4 Analytics
        const categoryDictionary = this.moduleStore.getModuleCategories("PRODUCTS");
        const categoryNameList = _compact(_map(product.DATA.Categories, (itemCategoryID) => {
            const category = _find(categoryDictionary, ['key', itemCategoryID]);
            return (category ? category.text : null);
        }));

        const productInfo = {
            productId: product.DATA.uid,
            productOption,
            productOptionText,
            productName: product.DATA.Name,
            productRetailPrice: (product.DATA.RetailPrice || product.DATA.DiscountedPrice),
            productDiscountedPrice: product.DATA.DiscountedPrice,
            productBrand: product.DATA.Brand,
            productCategoryList: categoryNameList,
            context: `Section Template : ${this.SectionName}`,
        }
        emitter.emit(EVENTS.CART.ADD, productInfo);
		event.stopPropagation();
    }

    stopPropagation(event){
        event.stopPropagation();
    }

    showShoppingCart(event){
		emitter.emit(EVENTS.CART.OPEN);
        event.stopPropagation();
    }

    async loadProductsInCollection(){
        let collection = null;
        if(this.Collections.length > 0)
            collection = this.Collections[0];
        
        if(collection){
            const X_SUBJECT_TYPE = {
                "uid":collection.DATA.uid,
                "Type":"PRODUCTS",
                "Category":"",
                "PREDICATE_TYPE":"ProductsInCollection",
                "FIND_WITHIN":true,
                "PAGE_SIZE":-1,
                "FIND_WITH_FILTERS":false,
                "REQUESTED_PAGE":1,
                "APP_OWNER": this.shopStore.OwnerInfo.SourceID,
                "ShopID": this.shopStore.ShopID
            };
            const shopCollectionStore = this.props.storeFactory.fetchStore("SHOPCOLLECTIONS", X_SUBJECT_TYPE);
            await shopCollectionStore.loadItems();
            runInAction("On Fetch SectionBaseTemplate ProductsInCollection from  shopCollectionStore, Success",() => {
                const categoryDictionary = this.moduleStore.getModuleCategories("PRODUCTS");
                //Added to set CategoryName(resolved name of category) for products in collection.
                _forEach(shopCollectionStore.ItemList, product => {
                    const category = _find(categoryDictionary, ['key', product.DATA.Category]);
                    const categoryName = category ? category.text : "$$$";
                    product.DATA.CategoryName = categoryName;
                });
            });
        }
    }

    async componentDidMount(){
        if(window.__INITIAL_STATE_SSR_DONE__ && window.__INITIAL_STATE_SSR_DONE__ === true){
            return;
        }
        await this.itemStore.loadItems();
        if(this.ShouldLoadProductsInCollection){
            this.loadProductsInCollection();
        }
    }

    renderSectionHeader(){
        if(this.SectionName){
            return(
                <StyledSectionHeader>
                    <span>{this.SectionName}</span>
                </StyledSectionHeader>
            );
        }else{
            return null;
        }
    }

    //Need to be overridden
    renderLoadingView(isMobileView){
        return null;
    }

    //Need to be overridden
    renderMobileView(){
        return null;
    }

    //Need to be overridden
    renderView(){
        return null;
    }

    render() {
        const collections = this.Collections;
        if(this.isLoading){
            return (
                <>
                    <StyledSectionContainer fluid className="section-base-template" key={this.SectionID}>
                        <Media lessThan="laptop">
                            {this.renderLoadingView(true)}
                        </Media>
                        <Media greaterThanOrEqual="laptop">
                            {this.renderLoadingView()}
                        </Media>
                    </StyledSectionContainer>
                    {
                        this.props.addSpacerSection
                        &&
                        <StyledSectionSpacer key={`spacer-${this.SectionID}`} />
                    }
                </>
            );
        } else if(collections && collections.length > 0){
            return  (
                <>
                    {
                        this.renderSectionHeader()
                    }
                    <StyledSectionContainer fluid className="section-base-template" key={this.SectionID} >
                        <StyledSectionBackgroundImage $bgImageURL={this.SectionBackgroundImage} $blendMode={this.ContentBlendMode.source} />
                        <Media lessThan="laptop">
                            {this.renderMobileView()}
                        </Media>
                        <Media greaterThanOrEqual="laptop">
                            {this.renderView()}
                        </Media>
                    </StyledSectionContainer>
                    {
                        this.props.addSpacerSection
                        &&
                        <StyledSectionSpacer key={`spacer-${this.SectionID}`} />
                    }
                </>
            );
        } else {
            return null;
        }
    }
}

export default SectionBaseTemplate;