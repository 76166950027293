import styled from "styled-components";

const BaseHeaderDiv = styled.div`
    
    --header-height: ${props => props.$headerThemeProps.height};
    --header-background-color: ${props => props.$headerThemeProps.backgroundColor};
    --header-primary-color: ${props => props.$headerThemeProps.primaryColor};
    --header-secondary-color: ${props => props.$headerThemeProps.secondaryColor};
    --header-font-size : ${props => props.$headerThemeProps.fontSize};

	&&& {
        background: var(--header-background-color);
        color: var(--header-primary-color);
        height: var(--header-height);
        font-size: var(--header-font-size);
        font-family: var(--primary-font);
    }
`;

export default {
    BaseHeaderDiv
}