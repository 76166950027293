import _map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import React from 'react';
import WhatsAppWidget from 'react-whatsapp-widget';
import { Grid, Icon, Image, Menu, Segment } from 'semantic-ui-react';
import FooterBaseTemplate from './base/FooterBaseTemplate';

@inject("storeFactory")
@observer
class FooterTemplate2 extends FooterBaseTemplate {

    //@override
    renderFooter() {
        const Style = this.TemplateStyle;
        const footerTagline = this.FooterTagline;
        return (
            <>
                <Style.FooterGrid container stackable columns={3} >
                    <Grid.Column textAlign="center" verticalAlign="middle" width="4">
                    </Grid.Column>
                    <Grid.Column textAlign="center" verticalAlign="middle" width="8">
                        <Style.LogoSegment basic>
                            <Image src={this.ShopLogo}/>
                        </Style.LogoSegment>
                        {
                            footerTagline
                            &&
                            <Style.LogoHeader size='medium'>{footerTagline}</Style.LogoHeader>
                        }
                        <Segment basic>
                            {
                                this.showSocialMedia
                                &&
                                <div key="social" className="socialMedia"> 
                                    {
                                        this.showFacebookInfo
                                        &&
                                        <Style.SocialIcon href={`https://www.facebook.com/${this.FacebookInfo}`} target="_blank">
                                            <Icon name='facebook f'/>
                                        </Style.SocialIcon>
                                    }
                                    {
                                        this.showTwitterInfo
                                        &&
                                        <Style.SocialIcon href={`https://www.twitter.com/${this.TwitterInfo}`} target="_blank">
                                            <Icon name='twitter' />
                                        </Style.SocialIcon>
                                    }
                                    {
                                        this.showInstagramInfo
                                        &&
                                        <Style.SocialIcon href={`https://www.instagram.com/${this.InstagramInfo}`} target="_blank">
                                            <Icon name='instagram'/>
                                        </Style.SocialIcon>
                                    }
                                    {
                                        
                                        this.showLinkedInInfo
                                        &&
                                        <Style.SocialIcon href={`https://www.linkedin.com/${this.LinkedInInfo}`} target="_blank">
                                            <Icon name='linkedin' inverted/>
                                        </Style.SocialIcon>
                                    }
                                    {
                                        this.showMobileInfo
                                        &&
                                        <WhatsAppWidget phoneNumber={this.MobileInfo} />
                                    }
                                </div>
                            }
                        </Segment>
                    </Grid.Column>
                    <Grid.Column textAlign="center" verticalAlign="middle" width="4">
                        <Style.ContactSegment basic $align="right">
                            <Style.ContactHeader>
                                <Style.ContactHeaderContent>
                                    Contact
                                </Style.ContactHeaderContent>
                                {
                                    this.showAddressInfo
                                    &&
                                    <Style.ContactSubHeader>
                                        <span>Address : </span>{this.AddressInfo}
                                    </Style.ContactSubHeader>
                                }
                                {
                                    this.showEmailInfo
                                    &&
                                    <Style.ContactSubHeader>
                                        <span>E-mail : </span><a href={`mailto:${this.EmailInfo}`}>{this.EmailInfo}</a>
                                    </Style.ContactSubHeader>
                                }
                                {
                                    this.showMobileInfo
                                    &&
                                    <Style.ContactSubHeader>
                                        <span>Phone : </span><a href={`tel:${this.MobileInfo}`}>{this.MobileInfo}</a>
                                    </Style.ContactSubHeader>
                                }
                            </Style.ContactHeader>
                        </Style.ContactSegment>
                    </Grid.Column>
                </Style.FooterGrid>
                <Style.FooterPageBar textAlign="center" basic>
                    <Menu text compact stackable>
                        {
                            _map(this.Pages, (page) => {
                                const isPageActive = (this.ActivePageID === page.uid);
                                return(
                                    <Style.FooterMenuItem
                                        link
                                        key={page.uid}
                                        active={isPageActive}
                                        onClick={this.onPageClick.bind(this, page.uid)}>
                                            <Style.FooterMenuItemName>
                                                {page.Name}
                                            </Style.FooterMenuItemName>
                                    </Style.FooterMenuItem>
                                );
                            })
                        }
                    </Menu>
                </Style.FooterPageBar>
            </>
        );
    }
}

export default FooterTemplate2;