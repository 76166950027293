import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import BannerBaseTemplate from '../base/BannerBaseTemplate';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

@inject('storeFactory')
@observer
export default class BannerTemplate2 extends BannerBaseTemplate {

    constructor(props){
        super(props);
    }

    renderMobileBanner(){
        const Style = this.TemplateStyle;
        const bannerImageURL = this.BannerImage;
        return(
            <Style.BannerHeader className="bannerHeader" $bannerImageURL={bannerImageURL} $forHomePage={this.props.forHomePage} $blendMode={this.ContentBlendMode.source}>
                <Style.BannerView $align="center" $blendMode={this.ContentBlendMode.source} >
                    <Style.BannerContent>
                        <Style.BannerLogo src={this.props.bannerLogo} />
                        <Style.BannerTitle $forHomePage={this.props.forHomePage} $blendMode={this.ContentBlendMode.source}>
                            {this.BannerTitle}
                            <Style.BannerTagline className="ql-editor" $blendMode={this.ContentBlendMode.source}>
                                {parse(this.BannerTagline)}
                            </Style.BannerTagline>
                        </Style.BannerTitle>
                    </Style.BannerContent>
                </Style.BannerView>
                {
                    this.props.children
                }
            </Style.BannerHeader>
        );
    }

    renderBanner(){
        const Style = this.TemplateStyle;
        const bannerImageURL = this.BannerImage;
        return(
            <Style.BannerHeader className="bannerHeader" $bannerImageURL={bannerImageURL} $forHomePage={this.props.forHomePage} $blendMode={this.ContentBlendMode.source}>
                <Style.BannerView $align={this.ContentAlign.source} $blendMode={this.ContentBlendMode.source} >
                    <Style.BannerContent>
                        <Style.BannerLogo src={this.props.bannerLogo} />
                        <Style.BannerTitle $forHomePage={this.props.forHomePage} $blendMode={this.ContentBlendMode.source}>
                            {this.BannerTitle}
                            <Style.BannerTagline className="ql-editor" $blendMode={this.ContentBlendMode.source}>
                                {parse(this.BannerTagline)}
                            </Style.BannerTagline>
                        </Style.BannerTitle>
                    </Style.BannerContent>
                </Style.BannerView>
                {
                    this.props.children
                }
            </Style.BannerHeader>
        );
    }
}