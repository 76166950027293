import _find from 'lodash/find';
import _times from 'lodash/times';
import _map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Eye, Plus, ShoppingCart } from 'react-feather';
import { Grid, Image, Pagination, Placeholder, Segment } from 'semantic-ui-react';
import CardViewBaseTemplate from './base/CardViewBaseTemplate';

@inject("storeFactory")
@observer
class CardViewTemplate2 extends CardViewBaseTemplate {

    stopPropagation(event){
        event.stopPropagation();
    }

    buildShoppingIcon(item){
        if(this.isAShop && (this.props.pageType === 'PRODUCT')){//Skip for pageType === 'COLLECTION'
            const Style = this.TemplateStyle;
            const itemID = item.DATA.uid;
            const inStock = item.DATA.InStock;
            const itemDiscountedPrice = item.DATA.DiscountedPrice;
            const itemRetailPrice = item.DATA.RetailPrice;

            if(inStock && itemDiscountedPrice){
                const isItemAddedInCart = this.isProductAddedInCart(itemID);
                if(isItemAddedInCart){
                    return(
                        <Style.ProductActionIcon bordered size="large" onClick={this.showShoppingCart}>
                            <ShoppingCart size="18px" />
                        </Style.ProductActionIcon>
                    );
                } else {

                    let productOption = 'ONE_SIZE';
                    let productOptionText = '';
                    const optionPredicate = item.DATA.CATEGORY_MASTERS_Size;
                    if(optionPredicate && item.DATA[optionPredicate]){
                        const firstOptionValue = item.DATA[optionPredicate][0];
                        const optionValueEntry = _find(item.OPTIONS[optionPredicate], ["key", firstOptionValue]);
                        if(optionValueEntry){
                            productOption = optionValueEntry.source;
                            productOptionText = optionValueEntry.text;
                        }
                    }

                    return(
                        <Style.ProductActionIcon bordered size="large" onClick={this.addToCart.bind(this, item, productOption, productOptionText)} $isAddInProgress={this.isAddInProgress}>
                            <Plus size="18px" />
                        </Style.ProductActionIcon>
                    );
                }
            } else {
                return(
                    <Style.ProductActionIcon bordered size="large" $isDisabled={true} onClick={this.stopPropagation}>
                        <Plus size="18px" />
                    </Style.ProductActionIcon>
                );
            }
        } else {
            return null;
        }
    }

    buildLoadingCardView(){
        const Style = this.TemplateStyle;
        return(
            <Segment basic>
                <Style.ProductImageDiv>
                    <Placeholder>
			          <Placeholder.Image square />
			        </Placeholder>
                </Style.ProductImageDiv>
                <Style.ProductInfoHeader>
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder.Line length='very long' />
                        </Placeholder.Header>
                        <Placeholder.Header>
                            <Placeholder.Line length='medium' />
                        </Placeholder.Header>
                    </Placeholder>
                    <Placeholder>
                        <Placeholder.Line length='very short' />
                        <Placeholder.Line length='medium' />
                    </Placeholder>
                </Style.ProductInfoHeader>
            </Segment>
        );
    }

    buildCardView(item){
        const Style = this.TemplateStyle;

        const itemID = item.DATA.uid;
        const itemName = item.DATA.Name;
        const itemDiscountedPrice = item.DATA.DiscountedPrice;
        const itemRetailPrice = item.DATA.RetailPrice;
        const itemCategory = item.DATA.Category;
        const itemCategorySource = item.DATA.CategorySourceID;
	    const itemCategoryName = this.getItemCategoryName(itemCategory);
        const itemURL = this.getItemURL(itemID, itemCategorySource);
        const itemImageURL = this.getItemImage(item);

        return(
            <Style.CardView basic onClick={this.handleOpenItemView.bind(this, itemID, itemCategorySource)}>
                <Style.ProductImageDiv>
                    <Image src={itemImageURL} fluid/>
                    <Style.ProductActionSegment basic>
                        <Style.ProductActionItemInfo>
                            <Style.ProductNameHeader textAlign="center">
                                {itemName}
                                <Style.ProductCategorySubHeader $margin="1rem 0 0">
                                    {itemCategoryName}
                                </Style.ProductCategorySubHeader>
                                <Style.ProductPriceSubHeader $margin="1rem 0 0">
                                    {
                                        itemDiscountedPrice
                                        &&
                                        <span className="price discountedPrice">{this.Currency.format(itemDiscountedPrice)}</span>
                                    }
                                </Style.ProductPriceSubHeader>
                            </Style.ProductNameHeader>
                        </Style.ProductActionItemInfo>
                        <Style.ProductActionIconInfo>
                            { this.buildShoppingIcon(item) }
                            <Style.ProductActionIcon bordered size="large">
                                <Eye size="18px" />
                            </Style.ProductActionIcon>
                        </Style.ProductActionIconInfo>
                    </Style.ProductActionSegment>
                </Style.ProductImageDiv>
                <Style.ProductInfoHeader>
                    <Style.ProductName href={itemURL}>{itemName}</Style.ProductName>
                    <Style.ProductCategorySubHeader $margin="0.285rem 0 0">
                        {itemCategoryName}
                    </Style.ProductCategorySubHeader>
                    <Style.ProductPriceSubHeader $margin="0.285rem 0 0">
                        {
                            itemRetailPrice
                            &&
                            <span className="price retailPrice">{this.Currency.format(itemRetailPrice)}</span>
                        }
                        {
                            itemDiscountedPrice
                            &&
                            <span className="price discountedPrice">{this.Currency.format(itemDiscountedPrice)}</span>
                        }
                    </Style.ProductPriceSubHeader>
                </Style.ProductInfoHeader>
            </Style.CardView>
        );
    }

    //@Overridden
    renderLoadingCardView(){
        const Style = this.TemplateStyle;
        const { itemsPerRow} = this.props;
        const count = itemsPerRow ? itemsPerRow : 4;
        return(
            <Style.CardViewGrid columns={itemsPerRow} stackable doubling>
                {
                    _times(count, counter => {
                        return (
                            <Grid.Column key={counter}>
                                {this.buildLoadingCardView()}
                            </Grid.Column>
                        );
                    })
                }
            </Style.CardViewGrid>
        );
    }

    //@Overridden
    renderCardView(){
        const Style = this.TemplateStyle;
        const { itemList, itemsPerRow} = this.props;
        return (
            <div>
                <Style.CardViewGrid columns={itemsPerRow} stackable doubling>
                    {
                        _map(itemList, item => {
                            const productID = item.DATA.uid;
                            return (
                                <Grid.Column key={productID}>
                                    {this.buildCardView(item)}
                                </Grid.Column>
                            );
                        })
                    }
                </Style.CardViewGrid>
                <Segment basic textAlign="center">
                    {
                        (this.TotalPageCount > 0)
                        &&
                        <Pagination
                            pointing 
                            secondary 
                            defaultActivePage={this.ActivePageIndex} 
                            totalPages={this.TotalPageCount}
                            firstItem={null}
                            lastItem={null}
                            onPageChange={this.handlePageNavAction} />
                    }
                </Segment>
            </div>
        );
    }

}

export default CardViewTemplate2;