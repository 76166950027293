import _times from 'lodash/times';
import _map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Container, Grid, Header, Placeholder, Segment } from 'semantic-ui-react';
import SectionBaseTemplate from './base/SectionBaseTemplate';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

@inject("storeFactory")
@observer
class SectionTemplate8 extends SectionBaseTemplate {

    //@Overridden
    renderLoadingView(){
        const Style = this.TemplateStyle;
        return(
            <Container style={{margin: "0"}} fluid>
                <Segment textAlign="center" basic>
                    <Style.SectionNameHeader textAlign="center">
                        <Placeholder style={{ margin: "auto" }}>
                            <Placeholder.Header>
                                <Placeholder.Line length='very long' />
                            </Placeholder.Header>
                        </Placeholder>
                    </Style.SectionNameHeader>
                </Segment>
                <Style.CollectionGrid columns="4" stackable doubling>
                    {
                        _times(4, counter => {
                            return(
                                <Grid.Column key={counter}>
                                    <Style.CollectionSegment basic >
                                        <Style.CollectionImageSegment basic>
                                            <Placeholder style={{ height: "100%" }}>
                                                <Placeholder.Image square />
                                            </Placeholder>
                                        </Style.CollectionImageSegment >
                                    </Style.CollectionSegment>
                                </Grid.Column>
                            );
                        })
                    }
                </Style.CollectionGrid>
            </Container>
        );
    }

    //@Overridden
    renderMobileView(){
        return this.renderView();
    }

    //@Overridden
    renderView(){
        const Style = this.TemplateStyle;
        return(
            <Container style={{margin: "0", padding: "2rem"}} fluid>
                <Style.CollectionGrid columns="3" stackable doubling>
                    {
                        _map(this.Collections, collection => {
                            const firstSoloImageURL = this.getCollectionProfileImage(collection);
                            return(
                                <Grid.Column key={collection.DATA.uid}>
                                    <Style.CollectionSegment basic >
                                        <Style.CollectionImageSegment basic>
                                            <Style.CollectionImage src={firstSoloImageURL} fluid/>
                                        </Style.CollectionImageSegment >
                                        <Style.CollectionInfoSegment basic>
                                            <Style.CollectionNameHeader>
                                                {collection.DATA.Name}
                                            </Style.CollectionNameHeader>
                                            <Style.LearnMore onClick={this.handleOpenCollectionView.bind(this, collection.DATA.uid, collection.DATA.Category)}>
                                                Learn More
                                            </Style.LearnMore>
                                        </Style.CollectionInfoSegment>
                                    </Style.CollectionSegment>
                                </Grid.Column>
                            );
                        })
                    }
                </Style.CollectionGrid>
            </Container>
        );
    }
}

export default SectionTemplate8;