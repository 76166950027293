import _filter from 'lodash/filter';
import { action } from 'mobx';
import React, { Component } from 'react';
import FooterBaseStyle from "./Style-FooterBaseTemplate";

/**
 * {props.templateType} 
 *  - 'TEMPLATE' : FooterTemplates are initialized without any HOC, hence the rendering is happening via Live Shop
 *  - 'WRAPPER'  : FooterTemplates are initialized with HOC - FooterTemplateWrapper, hence the rendering is happening via Shop Configuration View (App Side)
 *                 checkout (FooterTemplateWrapper)
 */
export default class FooterBaseTemplate extends Component {

    constructor(props){
        super(props);
        if(props.templateType === "TEMPLATE"){//The other templateType is "WRAPPER" (checkout 'HeaderTemplateWrapper')
            this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
            this.navigationStore = props.storeFactory.NavigationStore;
        }
    }

    get TemplateStyle(){
        return this.props.templateStyle;
    }

    get ShopLogo(){
        return this.shopStore.ShopLogo;
    }

    get ShopName(){
        return this.shopStore.ShopName;
    }

    get FooterTagline(){
        return this.shopStore.FooterTagline;
    }

    get Pages(){
        const pageList = this.shopStore.Pages;
        const pagesForHeader = _filter(pageList, 'ShowOnFooter');
        return pagesForHeader;
    }

    get ActivePageID(){
        return this.shopStore.ActivePage;
    }

    get showSocialMedia(){
        const shop = this.shopStore.getCurrentItem();
        return (shop ? shop.DATA.ShowSocialMedia : false);
    }

    get showShopInfo(){
        const shop = this.shopStore.getCurrentItem();
        return (shop ? shop.DATA.ShowInfo : false);
    }

    get showFacebookInfo(){
        const shop = this.shopStore.getCurrentItem();
        return (shop && shop.DATA.facebookurl && shop.DATA.facebookurl.trim() !== '' ? true : false);
    }

    get FacebookInfo(){
        const shop = this.shopStore.getCurrentItem();
        return shop.DATA.facebookurl;
    }

    get showTwitterInfo(){
        const shop = this.shopStore.getCurrentItem();
        return (shop && shop.DATA.twitterurl && shop.DATA.twitterurl.trim() !== '' ? true : false);
    }

    get TwitterInfo(){
        const shop = this.shopStore.getCurrentItem();
        return shop.DATA.twitterurl;
    }

    get showInstagramInfo(){
        const shop = this.shopStore.getCurrentItem();
        return (shop && shop.DATA.instagramurl && shop.DATA.instagramurl.trim() !== '' ? true : false);
    }

    get InstagramInfo(){
        const shop = this.shopStore.getCurrentItem();
        return shop.DATA.instagramurl;
    }

    get showLinkedInInfo(){
        const shop = this.shopStore.getCurrentItem();
        return (shop && shop.DATA.linkedin && shop.DATA.linkedin.trim() !== '' ? true : false);
    }

    get LinkedInInfo(){
        const shop = this.shopStore.getCurrentItem();
        return shop.DATA.linkedin;
    }

    get showMobileInfo(){
        const shop = this.shopStore.getCurrentItem();
        return (shop && shop.DATA.MobileNumber && shop.DATA.MobileNumber.trim() !== '' ? true : false);
    }

    get MobileInfo(){
        const shop = this.shopStore.getCurrentItem();
        return  `+91${shop.DATA.MobileNumber}`;
    }

    get showEmailInfo(){
        const shop = this.shopStore.getCurrentItem();
        return (shop && shop.DATA.Email && shop.DATA.Email.trim() !== '' ? true : false);
    }

    get EmailInfo(){
        const shop = this.shopStore.getCurrentItem();
        return shop.DATA.Email;
    }

    get showAddressInfo(){
        const shop = this.shopStore.getCurrentItem();
        const shopStreetAddress = shop && shop.DATA.streetaddress ? shop.DATA.streetaddress : "";
        const shopLandmark = shop && shop.DATA.locatedin ? shop.DATA.locatedin : "";
        const shopCity = shop && shop.DATA.city ? shop.DATA.city : "";
        const shopPincode = shop && shop.DATA.pincode ? shop.DATA.pincode : "";
        const shopState = shop && shop.DATA.state ? shop.DATA.state : "";
        const shopCountry = shop && shop.DATA.country ? shop.DATA.country : "";

        return ( 
            (shopStreetAddress && shopStreetAddress.trim() !== "")  
            || (shopLandmark && shopLandmark.trim() !== "") 
            || (shopCity && shopCity.trim() !== "") 
            || (shopPincode && shopPincode.trim() !== "") 
            || (shopState && shopState.trim() !== "") 
            || (shopCountry && shopCountry.trim() !== "") 
        );
    }   

    get AddressInfo(){
        const shop = this.shopStore.getCurrentItem();
        const shopStreetAddress = shop && shop.DATA.streetaddress ? shop.DATA.streetaddress : "";
        const shopLandmark = shop && shop.DATA.locatedin ? shop.DATA.locatedin : "";
        const shopCity = shop && shop.DATA.city ? shop.DATA.city : "";
        const shopPincode = shop && shop.DATA.pincode ? shop.DATA.pincode : "";
        const shopState = shop && shop.DATA.state ? shop.DATA.state : "";
        const shopCountry = shop && shop.DATA.country ? shop.DATA.country : "";

        let addressInfo = "";
        if(shopStreetAddress && shopStreetAddress.trim() !== "")
            addressInfo = addressInfo + `${shopStreetAddress}, `;
        if(shopLandmark && shopLandmark.trim() !== "")
            addressInfo = addressInfo + `${shopLandmark}, `;
        if((shopCity && shopCity.trim() !== "") || (shopPincode && shopPincode.trim() !== ""))
            addressInfo = addressInfo + `${shopCity} - ${shopPincode}, `;
        if((shopState && shopState.trim() !== "") || (shopCountry && shopCountry.trim() !== ""))
            addressInfo = addressInfo + `${shopState}, ${shopCountry}`;

        return addressInfo;
    }

    get FooterTheme(){
        const currentTheme = this.props.storeFactory.ThemeFactory.getThemeInfo(this.shopStore.ShopTheme.ThemeID);
        return currentTheme.FooterTheme;
    }

    @action
    onPageClick = (pageID) => {
        this.shopStore.ActivePage = pageID;
        if(this.shopStore.searchStore){
            this.shopStore.searchStore.clearSearchQuery();
            this.shopStore.searchStore.results = [];
            this.shopStore.searchStore.searchApplied = true;
        }
        this.navigationStore.changeView(this.navigationStore.VIEW.SHOP);
    }

    //Need to be overridden
    renderFooter(){
        return null;
    }

    render(){
        return(
            <FooterBaseStyle.BaseFooterDiv $footerThemeProps={this.FooterTheme}>
                {this.renderFooter()}
            </FooterBaseStyle.BaseFooterDiv>
        );
    }
}