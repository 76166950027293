import _filter from 'lodash/filter';
import _isNil from 'lodash/isNil';
import _find from 'lodash/find';
import { action } from 'mobx';
import React, { Component } from 'react';
import { ArrowLeft, X , Menu as MenuIcon} from 'react-feather';
import { emitter, EVENTS } from '../../../../../0-common/utils/EventEmitter';
import { Media } from '../../../../utils/Responsive';
import HeaderBaseStyle from "./Style-HeaderBaseTemplate";

/**
 * {props.templateType} 
 *  - 'TEMPLATE' : HeaderTemplates are initialized without any HOC, hence the rendering is happening via Live Shop
 *  - 'WRAPPER'  : HeaderTemplates are initialized with HOC - HeaderTemplateWrapper, hence the rendering is happening via Shop Configuration View (App Side)
 *                 checkout (HeaderTemplateWrapper)
 */
export default class HeaderBaseTemplate extends Component {

    constructor(props){
        super(props);
        if(props.templateType === "TEMPLATE"){//The other templateType is "WRAPPER" (checkout 'HeaderTemplateWrapper')
            this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
            this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
            this.shopUserStore = props.storeFactory.ShopUserStore;
            this.navigationStore = props.storeFactory.NavigationStore;
        }
    }
    
    get TemplateStyle(){
        return this.props.templateStyle;
    }

    get ShopLogo(){
        return this.shopStore.ShopLogo;
    }

    get Pages(){
        const pageList = this.shopStore.Pages;
        const pagesForHeader = _filter(pageList, 'ShowOnHeader');
        return pagesForHeader;
    }

    get ActivePageID(){
        return this.shopStore.ActivePage;
    }

    get SearchStore(){
        return this.shopStore.searchStore;
    }

    get FilterStore(){
        return this.shopStore.filterStore;
    }

    get isAShop(){
        const webShopCategory = this.shopStore.ShopCategory;
        return (webShopCategory === 'StandardWebShop');
    }

    get isAWebsite(){
        const webShopCategory = this.shopStore.ShopCategory;
        return (webShopCategory === 'StandardWebSite');
    }

    get isUserLoggedIn(){
        const buyer = this.shopUserStore.User;
        return !_isNil(buyer.DATA);
    }

    get isSearchBoxExpanded(){
        return this.shopStore.searchStore.expandSearchBox;
    }

    get UserDisplayName(){
        const buyer = this.shopUserStore.User;
        if(buyer.DATA)
            return buyer.DATA.Name;
        else 
            return "";
    }

    get CartCount(){
        return this.orderStore.ItemCountInCart;
    }

    get HeaderTheme(){
        const currentTheme = this.props.storeFactory.ThemeFactory.getThemeInfo(this.shopStore.ShopTheme.ThemeID);
        return currentTheme.HeaderTheme;
    }

    @action
    onLogoClick = () => {
        this.shopStore.ActivePage = this.shopStore.DefaultShopPageID;
        if(this.shopStore.searchStore){
            this.shopStore.searchStore.clearSearchQuery();
            this.shopStore.searchStore.results = [];
            this.shopStore.searchStore.searchApplied = true;
        }
        this.navigationStore.changeView(this.navigationStore.VIEW.SHOP);
        this.closeSideBarMenu();
    }

    @action
    onPageClick = (pageID) => {
        this.shopStore.ActivePage = pageID;
        if(this.shopStore.searchStore){
            this.shopStore.searchStore.clearSearchQuery();
            this.shopStore.searchStore.results = [];
            this.shopStore.searchStore.searchApplied = true;
        }
        this.navigationStore.changeView(this.navigationStore.VIEW.SHOP);
        this.closeSideBarMenu();
    }

    @action
    showBuyersProfileView = () => {
        this.shopStore.ActivePage = this.navigationStore.SUBVIEW.BUYER.PROFILE;
        this.navigationStore.changeView(this.navigationStore.VIEW.SHOP, this.navigationStore.SUBVIEW.BUYER.PROFILE);
		//this.props.navigationStore().changeView(this.props.navigationStore().VIEW.BUYERACCOUNT, this.props.navigationStore().SUBVIEW.BUYER.PROFILE);
        this.closeSideBarMenu();
    }

    @action
    showBuyersOrderView = () => {
        this.shopStore.ActivePage = this.navigationStore.SUBVIEW.BUYER.ORDER;
        this.navigationStore.changeView(this.navigationStore.VIEW.SHOP, this.navigationStore.SUBVIEW.BUYER.ORDER);
		//this.props.navigationStore().changeView(this.props.navigationStore().VIEW.BUYERACCOUNT, this.props.navigationStore().SUBVIEW.BUYER.ORDER);
        this.closeSideBarMenu();
    }

    @action
    showSignOutWarningPopup = () => {
        emitter.emit(EVENTS.SHOP.SIGNOUT);
    }

    @action
    showLoginView = () => {
        this.navigationStore.changeView(this.navigationStore.VIEW.SHOPLOGIN, this.navigationStore.SUBVIEW.SHOP.LOGIN);
        this.closeSideBarMenu();
    }

    showSideBarMenu = () => {
		emitter.emit(EVENTS.SHOP.SIDEBAR.OPEN);
    }

    closeSideBarMenu = () => {
		emitter.emit(EVENTS.SHOP.SIDEBAR.CLOSE);
    }

    goBackAction_Checkout = () => {
		const homeUrl = JSON.parse(localStorage.getItem('orderHomeURL'));
        window.location.href = homeUrl
    }

    @action
    goBackAction_Product = () => {
		//const productListUrl = JSON.parse(localStorage.getItem('productListURL_Back'));
        //window.location.href = productListUrl;
        //window.history.back();
        const productPageID = JSON.parse(localStorage.getItem('productPageID'));
        this.shopStore.ActivePage = productPageID;
        this.navigationStore.changeView(this.navigationStore.VIEW.SHOP);
    }

    goBackAction_Collection = () => {
        const homePageRef = _find(this.shopStore.Pages, {"CategorySourceID" : "HomePage"});
        this.shopStore.ActivePage = homePageRef.uid;
        this.navigationStore.changeView(this.navigationStore.VIEW.SHOP);
        //window.history.back();
    }

    @action
    showSearchBox = () => {
        this.shopStore.searchStore.expandSearchBox = true;
        this.closeSideBarMenu();
    }

    @action
    hideSearchBox = () => {
        this.shopStore.searchStore.expandSearchBox = false;
    }

    showShoppingCart = () => {
		emitter.emit(EVENTS.CART.OPEN);
        this.closeSideBarMenu();
    }

    renderMobileMenuIcon(){
        const Style = this.TemplateStyle;
        let menuIconType = "MENU";
        const navigationStore = this.navigationStore;
        const currentView = navigationStore.currentViewName;

        switch (currentView) {

            case navigationStore.VIEW.CHECKOUT:
                menuIconType = "BACK_CHECKOUT";
                break;
        
            default:
                const activePageID = this.shopStore.ActivePage; 
                const pageList = this.shopStore.Pages;
                const activePage = _find(pageList, ['uid', activePageID]);

                if(activePage){

                    switch (activePage.CategorySourceID) {
                        case "ProductPage" : 
                            let productSubView = navigationStore.currentSubView;
                            if(productSubView === navigationStore.SUBVIEW.NONE){
                                const shopParams = navigationStore.getContextualParam("SHOP"); 
                                const productID = shopParams.itemID;
                                if(productID){
                                    productSubView = navigationStore.SUBVIEW.SHOP.PRODUCT.OPEN;
                                } else {
                                    productSubView = navigationStore.SUBVIEW.SHOP.PRODUCT.LIST;
                                }
                            }
                            switch (productSubView) {
                                case navigationStore.SUBVIEW.SHOP.PRODUCT.OPEN:
                                    menuIconType = "BACK_PRODUCT";
                                    break;
                            
                                case navigationStore.SUBVIEW.SHOP.PRODUCT.LIST:
                                    const showSideBar = this.shopStore.showSideBar;
                                    if(showSideBar){
                                        menuIconType = "CLOSE";
                                    } else {
                                        menuIconType = "MENU";
                                    }
                                    break;
                            }
                            break;
                            
                        case "CollectionPage" : 
                            menuIconType = "BACK_COLLECTION";
                            break;
                        
                        default:
                            const showSideBar = this.shopStore.showSideBar;
                            if(showSideBar){
                                menuIconType = "CLOSE";
                            } else {
                                menuIconType = "MENU";
                            }
                            break;
                    }
                }
                break;
        }

        switch (menuIconType) {

            case "MENU":
                return(
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <MenuIcon size="40px" strokeWidth="0.5px" onClick={this.showSideBarMenu} />
                        <Style.MobileNavIconText>Menu</Style.MobileNavIconText>
                    </div>
                );
            
            case "CLOSE" :
                return(
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <X size="40px" strokeWidth="0.5px" onClick={this.closeSideBarMenu} />
                        <Style.MobileNavIconText>Close</Style.MobileNavIconText>
                    </div>
                );
            
            case "BACK_CHECKOUT" :
                return(
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <ArrowLeft size="40px" strokeWidth="0.5px" onClick={this.goBackAction_Checkout} />
                        <Style.MobileNavIconText>Back</Style.MobileNavIconText>
                    </div>
                );

            case "BACK_PRODUCT" :
                return(
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <ArrowLeft size="40px" strokeWidth="0.5px" onClick={this.goBackAction_Product} />
                        <Style.MobileNavIconText>Back</Style.MobileNavIconText>
                    </div>
                );

            case "BACK_COLLECTION" :
                return(
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <ArrowLeft size="40px" strokeWidth="0.5px" onClick={this.goBackAction_Collection} />
                        <Style.MobileNavIconText>Back</Style.MobileNavIconText>
                    </div>
                );
        }
    }

    renderPageMenuIcon(){
        const Style = this.TemplateStyle;
        const showSideBar = this.shopStore.showSideBar;
        if(showSideBar){
            return(
                <Style.PageMenuDiv onClick={this.closeSideBarMenu} >
                    <X size="40px" strokeWidth="0.5px" />
                    <Style.MobileNavIconText>Close</Style.MobileNavIconText>
                </Style.PageMenuDiv>
            );
        } else {
            return(
                <Style.PageMenuDiv onClick={this.showSideBarMenu} >
                    <MenuIcon size="40px" strokeWidth="0.5px" />
                    <Style.MobileNavIconText>Menu</Style.MobileNavIconText>
                </Style.PageMenuDiv>
            );
        }
    }

    //Need to be overridden
    renderMobileHeader(){
        return null;
    }

    //Need to be overridden
    renderHeader(){
        return null;
    }

    render(){
        return(
            <>
                <Media lessThan="laptop" className="responsiveHeader">
                    <HeaderBaseStyle.BaseHeaderDiv $headerThemeProps={this.HeaderTheme} className="shopHeader">
                        {this.renderMobileHeader()}
                    </HeaderBaseStyle.BaseHeaderDiv>
                </Media>
                <Media greaterThanOrEqual="laptop" className="responsiveHeader">
                    <HeaderBaseStyle.BaseHeaderDiv $headerThemeProps={this.HeaderTheme} className={this.props.headerClassList}>
                        {this.renderHeader()}
                    </HeaderBaseStyle.BaseHeaderDiv>
                </Media>
            </>
        );
    }
}