import _map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Grid, Image, Placeholder } from 'semantic-ui-react';
import SectionBaseTemplate from './base/SectionBaseTemplate';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

@inject("storeFactory")
@observer
class SectionTemplate5 extends SectionBaseTemplate {

    //@Overridden
    renderLoadingView(){
        const Style = this.TemplateStyle;
        return(
            <Style.CollectionGrid stackable>
                <Grid.Row columns={2}>
                    <Grid.Column width="8">
                        <Placeholder style={{maxWidth: "100%"}}>
                            <Placeholder.Image square />
                        </Placeholder>
                    </Grid.Column>
                    <Grid.Column stretched width="8">
                        <Style.CollectionInfoSegment basic>
                            <Style.CollectionInfoInnerSegment basic>
                                <Style.CollectionNameHeader>
                                    <Placeholder>
                                        <Placeholder.Header>
                                            <Placeholder.Line length='very long' />
                                        </Placeholder.Header>
                                        <Placeholder.Header>
                                            <Placeholder.Line length='medium' />
                                        </Placeholder.Header>
                                    </Placeholder>
                                </Style.CollectionNameHeader>
                                <Style.CollectionDescription>
                                    <Placeholder>
                                        <Placeholder.Paragraph>
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                        </Placeholder.Paragraph>
                                    </Placeholder>
                                </Style.CollectionDescription>
                                <Style.ViewMoreButton content="View More" disabled={true} />
                            </Style.CollectionInfoInnerSegment>
                        </Style.CollectionInfoSegment>
                    </Grid.Column>
                </Grid.Row>
            </Style.CollectionGrid>
        );
    }

    renderSectionHeader(){
        return null;
    }

    //@Overridden
    renderMobileView(){
        return this.renderView();
    }

    //@Overridden
    renderView(){
        const Style = this.TemplateStyle;
        const collections = this.Collections;

        return(
            <Style.CollectionGrid stackable>
                {
                    _map(collections, (collection) => {
                        const colImageURL = this.getCollectionProfileImage(collection);

                        return(
                            <Grid.Row columns={2} key={collection.DATA.uid}>
                                <Grid.Column width="8">
                                    <Image src={colImageURL} fluid/>
                                </Grid.Column >
                                <Grid.Column stretched width="8">
                                    <Style.CollectionInfoSegment basic>
                                        <Style.CollectionInfoInnerSegment basic>
                                            <Style.CollectionNameHeader>
                                                {collection.DATA.Name}
                                            </Style.CollectionNameHeader>
                                            <Style.CollectionDescription className="ql-editor">
                                                {parse(collection.DATA.Description_RT || collection.DATA.Description || "")}
                                            </Style.CollectionDescription>
                                            <Style.ViewMoreButton content="View More" onClick={this.handleOpenCollectionView.bind(this, collection.DATA.uid, collection.DATA.Category)}/>
                                        </Style.CollectionInfoInnerSegment>
                                    </Style.CollectionInfoSegment>
                                </Grid.Column>
                            </Grid.Row>
                        );
                    })
                }
            </Style.CollectionGrid>
        );
    }
}

export default SectionTemplate5;