import _map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import React from 'react';
import WhatsAppWidget from 'react-whatsapp-widget';
import { Icon, Label, Menu } from 'semantic-ui-react';
import FooterBaseTemplate from './base/FooterBaseTemplate';

@inject("storeFactory")
@observer
class FooterTemplate1 extends FooterBaseTemplate {

    //@override
    renderFooter() {
        const Style = this.TemplateStyle;
        return (
            <>
                <Style.FooterDivider />
                <Style.FooterSegment basic>
                    <Menu text compact stackable>
                        {
                            _map(this.Pages, (page) => {
                                const isPageActive = (this.ActivePageID === page.uid);
                                return(
                                    <Style.FooterMenuItem
                                        link
                                        key={page.key}
                                        name={page.Name}
                                        active={isPageActive}
                                        onClick={this.onPageClick.bind(this, page.uid)}  />
                                );
                            })
                        }
                    </Menu>
                    {
                        this.showSocialMedia
                        &&
                        <div style={{margin: "0.5rem"}}> 
                            {
                                this.showFacebookInfo
                                &&
                                <Style.SocialMediaInfoLabel basic as='a' href={`https://www.facebook.com/${this.FacebookInfo}`} target="_blank">
                                    <Icon name='facebook f' circular inverted/>
                                </Style.SocialMediaInfoLabel>
                            }
                            {
                                this.showTwitterInfo
                                &&
                                <Style.SocialMediaInfoLabel basic as='a' href={`https://www.twitter.com/${this.TwitterInfo}`} target="_blank">
                                    <Icon name='twitter' circular inverted/>
                                </Style.SocialMediaInfoLabel>
                            }
                            {
                                this.showInstagramInfo
                                &&
                                <Style.SocialMediaInfoLabel basic as='a' href={`https://www.instagram.com/${this.InstagramInfo}`} target="_blank">
                                    <Icon name='instagram' circular inverted/>
                                </Style.SocialMediaInfoLabel>
                            }
                            {
                                
                                this.showLinkedInInfo
                                &&
                                <Style.SocialMediaInfoLabel basic as='a' href={`https://www.linkedin.com/${this.LinkedInInfo}`} target="_blank">
                                    <Icon name='linkedin' circular inverted/>
                                </Style.SocialMediaInfoLabel>
                            }
                            {
                                this.showMobileInfo
                                &&
                                <WhatsAppWidget phoneNumber={this.MobileInfo} />
                            }
                        </div>
                    }
                    {
                        this.showShopInfo
                        &&
                        this.showEmailInfo
                        &&
                        <Style.EmailInfo>
                            <Label as='a' basic href={`mailto:${this.EmailInfo}`} target="_blank">
                                <Icon name='mail'/>
                                {this.EmailInfo}
                            </Label>
                        </Style.EmailInfo>
                    }
                </Style.FooterSegment>
            </>
        );
    }
}

export default FooterTemplate1;