import _times from 'lodash/times';
import _chunk from 'lodash/chunk';
import _map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Grid, Header, Image, Placeholder } from 'semantic-ui-react';
import SectionBaseTemplate from './base/SectionBaseTemplate';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

@inject("storeFactory")
@observer
class SectionTemplate4 extends SectionBaseTemplate {

    //@Overridden
    renderLoadingView(){
        const Style = this.TemplateStyle;
        return(
            <Grid style={{margin: "0"}} stackable>
                <Grid.Row columns={4} style={{padding: "0"}}>
                {
                    _times(4, counter => {
                        return(
                            <Style.CollectionGridColumn key={counter}>
                                <Style.CollectionSegment>
                                    <Placeholder style={{border: "0.5px solid rgba(0,0,0,.15)"}}>
                                        <Placeholder.Image square />
                                    </Placeholder>
                                </Style.CollectionSegment>
                            </Style.CollectionGridColumn>
                        )
                    })
                }
                </Grid.Row>
                <Grid.Row columns={4} style={{padding: "0"}}>
                {
                    _times(4, counter => {
                        return(
                            <Style.CollectionGridColumn key={counter}>
                                <Style.CollectionSegment>
                                    <Placeholder style={{border: "0.5px solid rgba(0,0,0,.15)"}}>
                                        <Placeholder.Image square />
                                    </Placeholder>
                                </Style.CollectionSegment>
                            </Style.CollectionGridColumn>
                        )
                    })
                }
                </Grid.Row>
            </Grid>
        );
    }

    //@Overridden
    renderMobileView(){
        return this.renderView();
    }

    //@Overridden
    renderView(){
        const Style = this.TemplateStyle;
        const collectionChunks = _chunk(this.Collections, 4);
        return(
            <Grid style={{margin: "0"}} stackable>
                <Grid.Row columns={4} style={{padding: "0"}}>
                {
                    _map(collectionChunks[0], collection => {
                        const firstSoloImageURL = this.getCollectionProfileImage(collection);

                        return(
                            <Style.CollectionGridColumn key={collection.DATA.uid}>
                                <Style.CollectionSegment onClick={this.handleOpenCollectionView.bind(this, collection.DATA.uid, collection.DATA.Category)} >
                                    <Image src={firstSoloImageURL} fluid/>
                                    <Style.CollectionInfoSegment>
                                        <Style.CollectionInfoHeader>
                                            {collection.DATA.Name}
                                            <Header.Subheader className="ql-editor">
                                                {parse(collection.DATA.Description_RT || collection.DATA.Description || "")}
                                            </Header.Subheader>
                                        </Style.CollectionInfoHeader>
                                    </Style.CollectionInfoSegment>
                                </Style.CollectionSegment>
                            </Style.CollectionGridColumn>
                        )
                    })
                }
                </Grid.Row>
                <Grid.Row columns={4} style={{padding: "0"}}>
                {
                    _map(collectionChunks[1], collection => {
                        const firstSoloImageURL = this.getCollectionProfileImage(collection);
                        return(
                            <Style.CollectionGridColumn key={collection.DATA.uid}>
                                <Style.CollectionSegment onClick={this.handleOpenCollectionView.bind(this, collection.DATA.uid, collection.DATA.Category)} >
                                    <Image src={firstSoloImageURL} fluid/>
                                    <Style.CollectionInfoSegment>
                                        <Style.CollectionInfoHeader>
                                            {collection.DATA.Name}
                                            <Header.Subheader className="ql-editor">
                                                {parse(collection.DATA.Description_RT || collection.DATA.Description || "")}
                                            </Header.Subheader>
                                        </Style.CollectionInfoHeader>
                                    </Style.CollectionInfoSegment>
                                </Style.CollectionSegment>
                            </Style.CollectionGridColumn>
                        )
                    })
                }
                </Grid.Row>
            </Grid>
        );
    }
}

export default SectionTemplate4;