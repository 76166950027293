import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

@inject('storeFactory', 'shopTemplateFactory')
@observer
export default class BannerSection extends Component {

    constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

	render() {
        //TODO Add a check to determine if the current section template is available for the currentTheme ?
        const themeSectionID = this.props.section.DATA.ThemeSectionID;// eg: - "Default-BannerSection1";
        const banner_template_ID =  this.shopStore.ShopTheme.getBannerTemplateID(themeSectionID)// eg: - "BannerTemplate1";
        const BannerTemplate = this.props.shopTemplateFactory.getTemplate(banner_template_ID);
        const shopLogo = this.shopStore.ShopLogo;
        if(BannerTemplate){
            return (<BannerTemplate {...this.props} bannerLogo={shopLogo} />);
        } else {
            return null;
        }
	}
}