import _find from 'lodash/find';
import React, { Component } from "react";
import { Container, Header } from "semantic-ui-react";
import styled from "styled-components";
import { Media } from "../../../../utils/Responsive";

const _DEFAULT_CONTENT_FLOW = "ltr";
const _DEFAULT_CONTENT_BLEND_MODE = "lightmode";

const StyledSectionSpacer = styled.div`
    width: 100%;
    height: 2.5rem;
    background: transparent;
`;

const StyledContainer = styled(Container)`
    position: relative;
    border: ${props => props.$wrapper ? '1px dotted #e3e3e3' : 'none'};
`;

const StyledContentBackgroundImage = styled.div`
    position: absolute;
    inset: 0;

    ${props => props.$bgImageURL && `
        background-image: url(${props.$bgImageURL});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        opacity: 0.8;
    `}
`;

const StyledSectionHeader = styled(Header)`
    &&&{
        font-family: var(--primary-font);
        font-size: 2rem;
        color : var(--secondary-color-dark);
        filter: brightness(0.5);
        font-weight: 400;
        text-align: center;
        margin: 1rem;
    }
`;


export default class ContentSectionBaseTemplate extends Component {

    get TemplateStyle(){
        return this.props.templateStyle;
    }

    get SectionID(){
        return this.props.section.DATA.uid;
    }

    get SectionName(){
        return this.props.section.DATA.Name;
    }

    get SectionBackgroundImage(){
        let profileImageID = null;
        const imageId = this.props.section.DATA.ProfileImage;
        if(imageId && imageId !== ""){
            profileImageID = this.props.section.REFERENCE_IMAGES[imageId].SourceID;
        }
        if(profileImageID){
            return "https://ucarecdn.com/"+profileImageID+"/-/format/auto/-/quality/smart/";
        } else {
            return null;
        }
    }

    get ContentImage(){
        let profileImageID = null;
        const imageIdList = this.props.section.DATA.ContentImage;
        if(imageIdList && imageIdList.length > 0){
            const imageId = imageIdList[0];
            profileImageID = this.props.section.REFERENCE_IMAGES[imageId].SourceID;
        }
        if(profileImageID){
            return "https://ucarecdn.com/"+profileImageID+"/-/format/auto/-/quality/smart/";
        } else {
            return null;
        }
    }

    get ContentDescription(){
        return this.props.section.DATA.Description_RT;
    }

    get ContentFlow(){
        const contentFlowOptions = this.props.section.OPTIONS["ContentFlowDirection"];
        let contentFlowDirection = _find(contentFlowOptions, ["source", _DEFAULT_CONTENT_FLOW]);
        const contentFlowDirectionId = this.props.section.DATA.ContentFlowDirection[0];
        if(contentFlowDirectionId){
            const contentFlowOption = _find(contentFlowOptions, ["value", contentFlowDirectionId]);
            if(contentFlowOption){
                contentFlowDirection = contentFlowOption;
            }
        }

        return contentFlowDirection;
    }

    get ContentBlendMode(){
        const contentBlendModeOptions = this.props.section.OPTIONS["ContentBlendMode"];
        let contentBlendMode = _find(contentBlendModeOptions, ["source", _DEFAULT_CONTENT_BLEND_MODE]);
        const contentBlendModeId = this.props.section.DATA.ContentBlendMode[0];
        if(contentBlendModeId){
            const contentBlendModeOption = _find(contentBlendModeOptions, ["value", contentBlendModeId]);
            if(contentBlendModeOption){
                contentBlendMode = contentBlendModeOption;
            }
        }

        return contentBlendMode;
    }

    renderSectionHeader(){
        if(this.SectionName){
            return(
                <StyledSectionHeader>
                    <span>{this.SectionName}</span>
                </StyledSectionHeader>
            );
        }else{
            return null;
        }
    }

    //Need to be overridden
    renderSectionImageView(){
        return null;
    }

    //Need to be overridden
    renderSectionDescriptionView(){
        return null;
    }

    //Need to be overridden
    renderMobileView(){
        return this.renderView();
    }

    //Need to be overridden
    renderView(){
        return null;
    }

    render() {
        /*if(this.props.templateType === "WRAPPER"){
            return this.renderDesignerView();
        }else{
            
        }*/
        return (
            <>
                {
                    this.renderSectionHeader()
                }
                <StyledContainer fluid className="content-section-base-template" key={this.SectionID} $wrapper={this.props.templateType === 'WRAPPER'}>
                    <StyledContentBackgroundImage $bgImageURL={this.SectionBackgroundImage} />
                    <Media lessThan="laptop">
                        {this.renderMobileView()}
                    </Media>
                    <Media greaterThanOrEqual="laptop">
                        {this.renderView()}
                    </Media>
                </StyledContainer>
                {
                    this.props.addSpacerSection
                    &&
                    <StyledSectionSpacer key={`spacer-${this.SectionID}`} />
                }
            </>
        );
    }
}