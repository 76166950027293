import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import { runInAction } from "mobx";
import { inject } from "mobx-react";

/**
 * SectionTemplateWrapper is responsible for masking all the call to parent/base SectionBaseTemplate. 
 * SectionBaseTemplate is designed with Live Shop.
 * In Shop Configuration View (App Side), SectionTemplateWrapper is used as a base to render HeaderTemplates.
 * 
 * SectionTemplateWrapper extends the SectionTemplate and overrides all the methods as per the preview requirement in Shop Configuration View (App Side)
 * 
 * @param {SectionTemplate} SectionTemplate 
 * @returns HOC of SectionTemplate
 */
export default function SectionTemplateWrapper(SectionTemplate){

    @inject("storeFactory")
    class StyledSectionTemplateWrapper extends SectionTemplate.wrappedComponent{

        _templateStyle = null;
        _templateType = null;

        constructor(props) {
            super(props);
            const X_SUBJECT_TYPE = {
                "uid":props.section.DATA.uid,
                "Type":"COLLECTIONS",
                "Category":"",
                "PREDICATE_TYPE":"CollectionsInSection",
                "FIND_WITHIN":true,
                "PAGE_SIZE":-1,
                "REQUESTED_PAGE":1,
            };
            this.itemStore = props.storeFactory.fetchStore("COLLECTIONS", X_SUBJECT_TYPE);
            this.moduleStore = props.storeFactory.ModuleStore;
            this.initialize();
        }

        initialize(){
            this._templateType = "SectionStyles";
            this._templateStyle = this.fetchTemplateStyle();
        }

        fetchTemplateStyle(){
            const themeID = this.props.storeFactory.ShopThemeStore.ThemeID;
            if(themeID){
                const styleMap = this.props.storeFactory.ThemeFactory.getThemeStyleMap(themeID);
                if(styleMap){
                    const templateTypeStyleMap = styleMap[this._templateType];
                    if(templateTypeStyleMap){
                        const templateStyleMap = templateTypeStyleMap[this.props.sectionTemplateID];
                        if(templateStyleMap){
                            return templateStyleMap;
                        } else {
                            console.warn(`StyledTemplate > 'templateTypeStyleMap[this.props.sectionTemplateID]' > templateStyleMap not found for templateID : ${this.props.sectionTemplateID}`);
                            console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for templateID : '${this.props.sectionTemplateID}', for _templateType : '${this._templateType}', configuration in src/shop/theme/<currentTheme>/style-mapping.js`);
                        }
                    } else {
                        console.warn(`StyledTemplate > 'styleMap[_templateType]' > templateTypeStyleMap not found for _templateType : ${this._templateType}`);
                        console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for _templateType : '${this._templateType}', configuration in src/shop/theme/<currentTheme>/style-mapping.js`);
                    }
                } else {
                    console.warn(`StyledTemplate > 'themeFactory.getThemeStyleMap' > StyleMap not found for theme ID : ${themeID}`);
                    console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for configuration in src/shop/theme/Theme-Library.js`);
                }
            } else {
                console.warn(`StyledTemplate > 'storeFactory.ShopThemeStore.ThemeID' > themeID not found for  ${getTemplateDisplayName(SectionTemplate)}`);
                console.info(` !! QUICK FIX !! : Please check themeID value in storeFactory.ShopThemeStore.ThemeID`);
            }
        }

        get TemplateStyle(){
            if(!this._templateStyle){
                this.initialize();
            }
            return this._templateStyle;
        }

        get Collections(){
            return this.itemStore.ItemList;
        }

        get isAddInProgress(){
            return false;
        }

        get isAShop(){
            return true;
        }

        get ProductsInCollection(){
            let collection = null;
            if(this.Collections.length > 0)
                collection = this.Collections[0];
            
            if(collection){
                const X_SUBJECT_TYPE = {
                    "uid":collection.DATA.uid,
                    "Type":"PRODUCTS",
                    "Category":"",
                    "PREDICATE_TYPE":"ProductsInCollection",
                    "FIND_WITHIN":true,
                    "PAGE_SIZE":-1,
                    "FIND_WITH_FILTERS":false,
                    "REQUESTED_PAGE":1,
                };
                const shopCollectionStore = this.props.storeFactory.fetchStore("COLLECTIONS", X_SUBJECT_TYPE);
                return shopCollectionStore.ItemList;
            }else{
                return [];
            }
        }

        getItemURL(itemID, itemCategory, itemType){
            return "javascript:void(0);";
        }

        isProductAddedInCart(productID){
            return false;
        }

        handleOpenCollectionView = (collectionID, collectionCategory) => {
            return false;
        }

        handleOpenProductView = (productID, productCategory) => {
            return false;
        }

        addToCart(productID, productOption, productOptionText, event){
            event.stopPropagation();
        }

        showShoppingCart(){
            return false;
        }

        async loadProductsInCollection(){
            let collection = null;
            if(this.Collections.length > 0)
                collection = this.Collections[0];
            
            if(collection){
                const X_SUBJECT_TYPE = {
                    "uid":collection.DATA.uid,
                    "Type":"PRODUCTS",
                    "Category":"",
                    "PREDICATE_TYPE":"ProductsInCollection",
                    "FIND_WITHIN":true,
                    "PAGE_SIZE":-1,
                    "FIND_WITH_FILTERS":false,
                    "REQUESTED_PAGE":1
                };
                const collectionStore = this.props.storeFactory.fetchStore("COLLECTIONS", X_SUBJECT_TYPE);
                await collectionStore.loadItems();
                runInAction("On Fetch SectionTemplateWrapper > ProductsInCollection from  CollectionStore, Success",() => {
                    const categoryDictionary = this.moduleStore.getModuleCategories("PRODUCTS");
                    //Added to set CategoryName(resolved name of category) for products in collection.
                    _forEach(shopCollectionStore.ItemList, product => {
                        const category = _find(categoryDictionary, ['key', product.DATA.Category]);
                        const categoryName = category ? category.text : "$$$";
                        product.DATA.CategoryName = categoryName;
                    });
                });
            }
        }

        async componentDidMount(){
            if(this.ShouldLoadProductsInCollection){
                this.loadProductsInCollection();
            }
        }

        //Need to be overridden
        renderMobileView(){
            return null;
        }

        render() {
            if(this._templateStyle)//Render the template only if _templateStyle is found/set.
                return super.render();
            else 
                return null;
        }
    }

    StyledSectionTemplateWrapper.displayName = `StyledSectionTemplateWrapper(${getTemplateDisplayName(SectionTemplate)})`; /** For better debugging */
    return StyledSectionTemplateWrapper;
}

function getTemplateDisplayName(Template) {
    return Template.displayName || Template.name || 'TemplateWrapper';
}
