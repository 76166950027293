
import _times from 'lodash/times';
import _map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Card, Image, Pagination, Placeholder, Segment } from 'semantic-ui-react';
import CardViewBaseTemplate from './base/CardViewBaseTemplate';

@inject("storeFactory")
@observer
class CardViewTemplate1 extends CardViewBaseTemplate {

    buildLoadingCardView(counter){
        return (
            <Card key={counter}>              
                <Placeholder>
                    <Placeholder.Image square />
                </Placeholder>
                <Card.Content>
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder.Line length='very short' />
                            <Placeholder.Line length='medium' />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                            <Placeholder.Line length='short' />
                        </Placeholder.Paragraph>
                    </Placeholder>
                </Card.Content>
                <Card.Content extra>
                    <Placeholder>
                        <Placeholder.Line length='very long' />
                    </Placeholder>
                </Card.Content>
            </Card>
        );
    }

    buildCardView(item){
        const Style = this.TemplateStyle;

        const itemID = item.DATA.uid;
        const itemName = item.DATA.Name;
        const itemImageURL = this.getItemImage(item);

        const itemCategory = item.DATA.Category;
        const itemCategorySource = item.DATA.CategorySourceID;
	    const itemCategoryName = this.getItemCategoryName(itemCategory);

        const inStock = item.DATA.InStock;
        let discountPercentage = null;
        const itemDiscountedPrice = item.DATA.DiscountedPrice;
        const itemRetailPrice = item.DATA.RetailPrice;
        if( itemDiscountedPrice && itemRetailPrice){
            discountPercentage = Math.round(((itemRetailPrice - itemDiscountedPrice) / itemRetailPrice) * 100);
        }
        const itemURL = this.getItemURL(itemID, itemCategorySource);

        return(
            <Style.CardView as="div" className="ui card" raised key={itemID} onClick={this.handleOpenItemView.bind(this, itemID, itemCategorySource)} >
                <Image src={itemImageURL} fluid/>
                <Card.Content>
                    <Style.ProductInfo>
                        <Style.ProductCategory basic>
                            {itemCategoryName}
                        </Style.ProductCategory>
                        {
                            this.isAShop
                            &&
                            inStock
                            &&
                            <Style.ProductInStock basic content="In Stock"  color="green" />
                        }
                        {
                            this.isAShop
                            &&
                            !inStock
                            &&
                            <Style.ProductInStock basic content="Out Of Stock"  color="red" />
                        }
                    </Style.ProductInfo>
                    <Style.ProductName href={itemURL} onClick={this.handleOpenItemView.bind(this, itemID, itemCategorySource)}>{itemName}</Style.ProductName>
                    <Style.ProductPrice>
                        {
                            itemRetailPrice
                            &&
                            <span className="product-retailPrice">{this.Currency.format(itemRetailPrice)}</span>
                        }
                        {
                            itemDiscountedPrice
                            &&
                            <span className="product-discountedPrice">{this.Currency.format(itemDiscountedPrice)}</span>
                        }
                        {
                            (discountPercentage > 0)
                            &&
                            <span className="product-discountPercentage">( {discountPercentage}% OFF )</span>
                        }
                    </Style.ProductPrice>
                </Card.Content>
            </Style.CardView>
        );
    }

    //@Overridden
    renderLoadingCardView(){
        const { itemsPerRow} = this.props;
        const count = itemsPerRow ? itemsPerRow : 4;
        return(
            <Card.Group stackable doubling itemsPerRow={itemsPerRow}>
            {
                _times(count, counter => {
                    return this.buildLoadingCardView(counter);
                })
            }
            </Card.Group>
        );
    }

    //@Overridden
    renderCardView(){
        const { itemList, itemsPerRow} = this.props;
        return (
            <div>
                <Card.Group stackable doubling itemsPerRow={itemsPerRow}>
                {
                    _map(itemList, item => {
                        return this.buildCardView(item);
                    })
                }
                </Card.Group>
                <Segment basic textAlign="center">
                    {
                        (this.TotalPageCount > 0)
                        &&
                        <Pagination
                            pointing 
                            secondary 
                            defaultActivePage={this.ActivePageIndex} 
                            totalPages={this.TotalPageCount}
                            firstItem={null}
                            lastItem={null}
                            onPageChange={this.handlePageNavAction} />
                    }
                </Segment>
            </div>
        );
    }
}

export default CardViewTemplate1;