import _find from 'lodash/find';
import React, { Component } from "react";
import styled from 'styled-components';
import { Media } from '../../../utils/Responsive';


const _DEFAULT_CONTENT_FLOW = "ltr";
const _DEFAULT_CONTENT_ALIGN = "center";
const _DEFAULT_CONTENT_BLEND_MODE = "lightmode";

const StyledSectionSpacer = styled.div`
    width: 100%;
    height: 2.5rem;
    background: transparent;
`;

export default class BannerBaseTemplate extends Component {

    constructor(props){
        super(props);
    }

    get TemplateStyle(){
        return this.props.templateStyle;
    }

    get BannerLogo(){
        return this.props.section.DATA.Name;
    }

    get BannerTitle(){
        return this.props.section.DATA.Name;
    }

    get BannerTagline(){
        return this.props.section.DATA.Description_RT || this.props.section.DATA.Description;
    }

    get IsBannerImageSet(){
        let isBannerImageSet = false;
        const bannerImageID = this.props.section.DATA.ProfileImage;
        if(bannerImageID){
            isBannerImageSet = true;
        }

        return isBannerImageSet;
    }

    get BannerImage(){
        let bannerImageURL = null;
        const bannerImageID = this.props.section.DATA.ProfileImage;
        if(bannerImageID){
            const bannerImage = this.props.section.REFERENCE_IMAGES[bannerImageID];
            if(bannerImage){
                bannerImageURL = `https://ucarecdn.com/${bannerImage.SourceID}/-/format/auto/-/quality/smart/-/progressive/yes/`;
            }
        }

        return bannerImageURL;
    }

    get ContentFlow(){
        const contentFlowOptions = this.props.section.OPTIONS["ContentFlowDirection"];
        let contentFlowDirection = _find(contentFlowOptions, ["source", _DEFAULT_CONTENT_FLOW]);
        const contentFlowDirectionId = this.props.section.DATA.ContentFlowDirection ? this.props.section.DATA.ContentFlowDirection[0] : null;
        if(contentFlowDirectionId){
            const contentFlowOption = _find(contentFlowOptions, ["value", contentFlowDirectionId]);
            if(contentFlowOption){
                contentFlowDirection = contentFlowOption;
            }
        }

        return contentFlowDirection;
    }

    get ContentAlign(){
        const contentAlignOptions = this.props.section.OPTIONS["ContentAlign"];
        let contentAlign = _find(contentAlignOptions, ["source", _DEFAULT_CONTENT_ALIGN]);
        const contentAlignId = this.props.section.DATA.ContentAlign ? this.props.section.DATA.ContentAlign[0] : null;
        if(contentAlignId){
            const contentAlignOption = _find(contentAlignOptions, ["value", contentAlignId]);
            if(contentAlignOption){
                contentAlign = contentAlignOption;
            }
        }

        return contentAlign;
    }

    get ContentBlendMode(){
        const contentBlendModeOptions = this.props.section.OPTIONS["ContentBlendMode"];
        let contentBlendMode = _find(contentBlendModeOptions, ["source", _DEFAULT_CONTENT_BLEND_MODE]);
        const contentBlendModeId = this.props.section.DATA.ContentBlendMode ? this.props.section.DATA.ContentBlendMode[0] : null;
        if(contentBlendModeId){
            const contentBlendModeOption = _find(contentBlendModeOptions, ["value", contentBlendModeId]);
            if(contentBlendModeOption){
                contentBlendMode = contentBlendModeOption;
            }
        }

        return contentBlendMode;
    }

    //Need to be overridden
    renderMobileBanner(){
        return null;
    }

    //Need to be overridden
    renderBanner(){
        return null;
    }

    render(){
        const isBannerImageSet = this.IsBannerImageSet;
        if(isBannerImageSet){
            return(
                <>
                    <div className="bannerSection">
                        <Media lessThan="laptop">
                            {this.renderMobileBanner()}
                        </Media>
                        <Media greaterThanOrEqual="laptop">
                            {this.renderBanner()}
                        </Media>
                    </div>
                    {
                        this.props.addSpacerSection
                        &&
                        <StyledSectionSpacer key={`spacer-${this.SectionID}`} />
                    }
                </>
            );
        }
        //Case : When no banner image is set;
        return null;
    }
}