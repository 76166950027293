import _map from 'lodash/map';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Slider from 'react-slick';
import { Grid, Image, Placeholder } from 'semantic-ui-react';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ph_emptyImageURL } from '../../../../0-common/utils/DefaultImage';
import { Media } from '../../../utils/Responsive';
import SectionBaseTemplate from './base/SectionBaseTemplate';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

@inject("storeFactory")
@observer
export default class SectionTemplate1 extends SectionBaseTemplate {

    constructor(props) {
        super(props);
    }

    sliderRef = null;
    carousalSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: null,
        prevArrow: null,
        adaptiveHeight: false,//Changed it to false bcoz when set to true, due to setTimeout being used inside react-slick > slider code, afterChange / onSliderChangeHandler is not being called sometimes.
        afterChange: this.onSliderChangeHandler.bind(this)
    };

    @observable activeSlideIndex = 0;

    @computed
    get ActiveSlide(){
        return this.activeSlideIndex;
    }

    set ActiveSlide(currentIndex){
        this.activeSlideIndex = currentIndex;
    }

    @action
    onSliderChangeHandler(current){
        this.ActiveSlide = current;
    }

    nextCollection = () => {
        this.sliderRef.slickNext();
    }

    previousCollection = () => {
        this.sliderRef.slickPrev();
    }

    buildImageURLsForSoloView(item, index = "FIRST"){
        let soloImageSourceID = null;
        const productsInCollection = item.DATA.ProductsInCollection;
        if(productsInCollection && productsInCollection.length > 0){
            let productId = null;
            switch (index) {
                case "FIRST":
                    productId = productsInCollection[0];
                    break;
                case "LAST":
                    productId = productsInCollection[productsInCollection.length - 1];
                    break;
            }
            if(productId) {
                const imageId = item.REFERENCES[productId].ProfileImage;
                if(imageId && imageId !== ""){
                    soloImageSourceID = item.REFERENCE_IMAGES[imageId].SourceID;
                }
            } else {
                return null;
            }
        }
		if(soloImageSourceID){
            return "https://ucarecdn.com/"+soloImageSourceID+"/-/format/auto/-/quality/smart/";
        } else {
            return ph_emptyImageURL;
        }
	}

    //@Overridden
    renderLoadingView(isMobileView){
        const Style = this.TemplateStyle;
        const flowMode = this.props.flowMode;
        if(isMobileView){
            return(
                <Style.MobileSectionContainer fluid>
                    <Slider {...this.carousalSettings}>
                        <Style.MobileSliderComponent>
                            <Grid columns={2}>
                                <Grid.Row style={{padding: "0"}}>
                                    <Grid.Column width={8} style={{padding: "0"}}>
                                        <Placeholder style={{height: "100%", border: "0.5px solid rgba(0,0,0,.05)"}}>
                                            <Placeholder.Image square />
                                        </Placeholder>
                                    </Grid.Column>
                                    <Grid.Column width={8} style={{padding: "0"}}>
                                        <Placeholder style={{height: "100%", border: "0.5px solid rgba(0,0,0,.05)"}}>
                                            <Placeholder.Image square />
                                        </Placeholder>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Style.MobileSliderComponent>
                    </Slider>
                </Style.MobileSectionContainer>
            );
        } else {
            return(
                <Style.SectionContainer fluid>
                    <Style.SectionHeaderSegment basic>
                        <Style.SectionHeader size="medium">
                            <Placeholder>
                                <Placeholder.Header>
                                    <Placeholder.Line length='very long' />
                                </Placeholder.Header>
                            </Placeholder>
                        </Style.SectionHeader>
                        <Style.ActionSegment basic>
                            <Style.Slider.PrevArrow secondary circular icon='angle left' size="large" disabled={true}/>
                            <Style.Slider.NextArrow secondary circular icon='angle right' size="big" disabled={true} />
                        </Style.ActionSegment>
                    </Style.SectionHeaderSegment>
                    <Style.Slider.SliderComponent  ref={slider => (this.sliderRef = slider)} {...this.carousalSettings}>
                        <div>
                            <Style.Slider.SliderGrid>
                                <Grid.Row columns={3} style={{padding: "0"}}>
                                    {
                                        flowMode === "FORWARD"
                                        &&
                                        <>  
                                            <Grid.Column width={4} style={{padding: "0"}}>
                                                <Placeholder style={{height: "100%"}}>
                                                    <Placeholder.Image square />
                                                </Placeholder>
                                            </Grid.Column>
                                            <Grid.Column width={4} style={{padding: "0"}}>
                                                <Placeholder style={{height: "100%"}}>
                                                    <Placeholder.Image square />
                                                </Placeholder>
                                            </Grid.Column>
                                        </>
                                    }
                                    <Grid.Column width={8} style={{padding: "0"}}>
                                        <Style.CollectionInfo basic>
                                            <Style.CollectionName>
                                                <Placeholder>
                                                    <Placeholder.Header>
                                                        <Placeholder.Line length='very long' />
                                                    </Placeholder.Header>
                                                    <Placeholder.Header>
                                                        <Placeholder.Line length='medium' />
                                                    </Placeholder.Header>
                                                </Placeholder>
                                            </Style.CollectionName>
                                            <Placeholder>
                                                <Placeholder.Paragraph>
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                </Placeholder.Paragraph>
                                            </Placeholder>
                                            <Style.MoreButton secondary content="more" disabled={true} />
                                        </Style.CollectionInfo>
                                    </Grid.Column>
                                    {
                                        flowMode === "BACKWARD"
                                        &&
                                        <>  
                                            <Grid.Column width={4} style={{padding: "0"}}>
                                                <Placeholder style={{height: "100%"}}>
                                                    <Placeholder.Image square />
                                                </Placeholder>
                                            </Grid.Column>
                                            <Grid.Column width={4} style={{padding: "0"}}>
                                                <Placeholder style={{height: "100%"}}>
                                                    <Placeholder.Image square />
                                                </Placeholder>
                                            </Grid.Column>
                                        </>
                                    }
                                </Grid.Row>
                            </Style.Slider.SliderGrid>
                        </div>
                    </Style.Slider.SliderComponent>
                </Style.SectionContainer>
            );
        }
    }

    //@Overridden
    renderSectionHeader(){
        const Style = this.TemplateStyle;
        const collections = this.Collections;
        return(
            <Style.SectionHeaderSegment basic>
                <Style.SectionHeader size="medium">
                    <span>{this.SectionName}</span>
                </Style.SectionHeader>
                <Style.ActionSegment basic>
                    <Style.Slider.PrevArrow secondary circular icon='angle left' size="large" onClick={this.previousCollection} disabled={this.ActiveSlide === 0}/>
                    <Style.Slider.NextArrow secondary circular icon='angle right' size="big" onClick={this.nextCollection} disabled={this.ActiveSlide === (collections.length - 1)} />
                </Style.ActionSegment>
            </Style.SectionHeaderSegment>
        );
    }

    //@Overridden
    renderView(){
        const collections = this.Collections;
        const flowMode = this.props.isSectionOdd ? "FORWARD" : "BACKWARD";
        this.carousalSettings.dots = false;
        const Style = this.TemplateStyle;
        return(
            <Style.SectionContainer fluid>
                <Style.Slider.SliderComponent  ref={slider => (this.sliderRef = slider)} {...this.carousalSettings}>
                    {
                        _map(collections, (collection) => {
                            const firstSoloImageURL = this.buildImageURLsForSoloView(collection, "FIRST");
                            const lastSoloImageURL = this.buildImageURLsForSoloView(collection, "LAST");
                            return(
                                <div key={collection.DATA.uid}>
                                    <Style.Slider.SliderGrid key={collection.DATA.uid}>
                                        <Grid.Row columns={3} style={{padding: "0"}}>
                                            {
                                                flowMode === "FORWARD"
                                                &&
                                                <>  
                                                    <Grid.Column width={4} style={{padding: "0"}}>
                                                        <Style.CollectionImage wrapped src={firstSoloImageURL} />
                                                    </Grid.Column>
                                                    <Grid.Column width={4} style={{padding: "0"}}>
                                                        <Style.CollectionImage wrapped src={lastSoloImageURL} />
                                                    </Grid.Column>
                                                </>
                                            }
                                            <Grid.Column width={8} style={{padding: "0"}}>
                                                <Style.CollectionInfo basic>
                                                    <Style.CollectionName>
                                                        {collection.DATA.Name}
                                                    </Style.CollectionName>
                                                    <Style.CollectionDesc className="ql-editor">
                                                        {parse(collection.DATA.Description_RT || collection.DATA.Description || "")}
                                                    </Style.CollectionDesc>
                                                    <Style.MoreButton secondary content="more" onClick={this.handleOpenCollectionView.bind(this, collection.DATA.uid, collection.DATA.Category, this.SectionID)} />
                                                </Style.CollectionInfo>
                                            </Grid.Column>
                                            {
                                                flowMode === "BACKWARD"
                                                &&
                                                <>  
                                                    <Grid.Column width={4} style={{padding: "0"}}>
                                                        <Style.CollectionImage wrapped src={firstSoloImageURL} />
                                                    </Grid.Column>
                                                    <Grid.Column width={4} style={{padding: "0"}}>
                                                        <Style.CollectionImage wrapped src={lastSoloImageURL} />
                                                    </Grid.Column>
                                                </>
                                            }
                                        </Grid.Row>
                                    </Style.Slider.SliderGrid>
                                </div>
                            );
                        })
                    }
                </Style.Slider.SliderComponent>
            </Style.SectionContainer>
        );
    }

    //@Overridden
    renderMobileView(){
        const Style = this.TemplateStyle;
        const collections = this.Collections;
        this.carousalSettings.dots = true;
        return(
            <Style.MobileSectionContainer fluid>
                <Style.MobileSectionHeader>
                    <span>{this.SectionName}</span>
                </Style.MobileSectionHeader>
                <Style.MobileSliderComponent ref={slider => (this.sliderRef = slider)} {...this.carousalSettings}>
                    {
                        _map(collections, (collection) => {
                            const firstSoloImageURL = this.buildImageURLsForSoloView(collection, "FIRST");
                            const lastSoloImageURL = this.buildImageURLsForSoloView(collection, "LAST");
                            return(
                                <Style.MobileSliderView key={collection.DATA.uid} onClick={this.handleOpenCollectionView.bind(this, collection.DATA.uid, collection.DATA.Category, this.SectionID)}>
                                    <Grid columns={2} key={collection.DATA.uid}>
                                        <Grid.Row style={{padding: "0"}}>
                                            <Grid.Column width={8} style={{padding: "0"}}>
                                                <Image wrapped className="collectionImage" src={firstSoloImageURL} />
                                            </Grid.Column>
                                            <Grid.Column width={8} style={{padding: "0"}}>
                                                <Image wrapped className="collectionImage" src={lastSoloImageURL} />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Style.MobileCollectionInfo basic>
                                        <Style.MobileCollectionName>
                                            {collection.DATA.Name}
                                        </Style.MobileCollectionName>
                                        <Media at="tablet">
                                            <Style.MobileCollectionDesc className="ql-editor">
                                                {parse(collection.DATA.Description_RT || collection.DATA.Description || "")}
                                            </Style.MobileCollectionDesc>
                                        </Media>
                                    </Style.MobileCollectionInfo>
                                </Style.MobileSliderView>
                            );
                        })
                    }
                </Style.MobileSliderComponent>
            </Style.MobileSectionContainer>
        );
    }
}