/* Footer Template Library */
import FooterTemplate1 from "./footer/footer-template/FooterTemplate-1";
import FooterTemplate2 from "./footer/footer-template/FooterTemplate-2";
import FooterTemplate3 from "./footer/footer-template/FooterTemplate-3";

/* Header Template Library */
import HeaderTemplate1 from "./header/header-template/HeaderTemplate-1";
import HeaderTemplate2 from "./header/header-template/HeaderTemplate-2";

/* Section Template Library */
import SectionTemplate1 from "./home/section-template/SectionTemplate-1";
import SectionTemplate2 from "./home/section-template/SectionTemplate-2";
import SectionTemplate3 from "./home/section-template/SectionTemplate-3";
import SectionTemplate4 from "./home/section-template/SectionTemplate-4";
import SectionTemplate5 from "./home/section-template/SectionTemplate-5";
import SectionTemplate6 from "./home/section-template/SectionTemplate-6";
import SectionTemplate7 from "./home/section-template/SectionTemplate-7";
import SectionTemplate8 from "./home/section-template/SectionTemplate-8";

/* Content Section Template Library */
import ContentSectionTemplate1 from "./home/content-section-template/ContentSectionTemplate-1";

/* Product Template Library */
import CardViewTemplate1 from "./product/card-view-template/CardViewTemplate-1";
import CardViewTemplate2 from "./product/card-view-template/CardViewTemplate-2";

/* Banner Template Library */
import BannerTemplate1 from "./banner/banner-template/BannerTemplate-1";
import BannerTemplate2 from "./banner/banner-template/BannerTemplate-2";


/* 
    Footer Template Library
    {
        key => Footer Template Id,
        value => Footer Template Component
    }
*/
const FooterTemplateLibrary = {
    FooterTemplate1,
    FooterTemplate2,
    FooterTemplate3
};

/* 
    Header Template Library
    {
        key => Header Template ID,
        value => Header Template Component
    }
*/
const HeaderTemplateLibrary = {
    HeaderTemplate1,
    HeaderTemplate2
};

/* 
    Section Template Library
    {
        key => Section Template ID,
        value => Section Template Component
    }
*/
const SectionTemplateLibrary = {
    SectionTemplate1,
    SectionTemplate2,
    SectionTemplate3,
    SectionTemplate4,
    SectionTemplate5,
    SectionTemplate6,
    SectionTemplate7,
    SectionTemplate8,
};

/* 
    Content Section Template Library
    {
        key => Content Section Template ID,
        value => Content Section Template Component
    }
*/
const ContentSectionTemplateLibrary = {
    ContentSectionTemplate1
};

/* 
    Product Template Library
    {
        key => Card View Template ID,
        value => Card View Template Component
    }
*/
const ProductTemplateLibrary = {
    CardViewTemplate1,
    CardViewTemplate2
};

/* 
    Banner Template Library
    {
        key => Banner Template ID,
        value => Banner Template Component
    }
*/
const BannerTemplateLibrary = {
    BannerTemplate1,
    BannerTemplate2
};

export default {
    FooterTemplateLibrary,
    HeaderTemplateLibrary,
    SectionTemplateLibrary,
    ContentSectionTemplateLibrary,
    ProductTemplateLibrary,
    BannerTemplateLibrary
}