import styled from "styled-components";

const BaseFooterDiv = styled.div`
    
    --footer-height: ${props => props.$footerThemeProps.height};
    --footer-background-color: ${props => props.$footerThemeProps.backgroundColor};
    --footer-primary-color: ${props => props.$footerThemeProps.primaryColor};
    --footer-secondary-color: ${props => props.$footerThemeProps.secondaryColor};
    --footer-font-size : ${props => props.$footerThemeProps.fontSize};

	&&& {
        background: var(--footer-background-color);
        color: var(--footer-primary-color);
        height: var(--footer-height);
        font-size: var(--footer-font-size);
        font-family: var(--primary-font);
    }
`;

export default {
    BaseFooterDiv
}