import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import BannerBaseTemplate from '../base/BannerBaseTemplate';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

@inject('storeFactory')
@observer
export default class BannerTemplate1 extends BannerBaseTemplate {

    constructor(props){
        super(props);
    }

    renderMobileBanner(){
        const Style = this.TemplateStyle;
        const bannerImageURL = this.BannerImage;
        return(
            <Style.BannerHeader className="bannerHeader" $bannerImageURL={bannerImageURL} $forHomePage={this.props.forHomePage} $blendMode={this.ContentBlendMode.source}>
                <Style.BannerTitle $blendMode={this.ContentBlendMode.source} $align="center" >
                    {this.BannerTitle}
                    <Style.BannerTagline className="ql-editor" $blendMode={this.ContentBlendMode.source} $align="center">
                        {parse(this.BannerTagline)}
                    </Style.BannerTagline>
                </Style.BannerTitle>
                {
                    this.props.children
                }
            </Style.BannerHeader>
        );
    }

    renderBanner(){
        const Style = this.TemplateStyle;
        const bannerImageURL = this.BannerImage;
        return(
            <Style.BannerHeader className="bannerHeader" $bannerImageURL={bannerImageURL} $forHomePage={this.props.forHomePage} $blendMode={this.ContentBlendMode.source}>
                <Style.BannerTitle $blendMode={this.ContentBlendMode.source} $align={this.ContentAlign.source} >
                    {this.BannerTitle}
                    <Style.BannerTagline className="ql-editor" $blendMode={this.ContentBlendMode.source} $align={this.ContentAlign.source}>
                        {parse(this.BannerTagline)}
                    </Style.BannerTagline>
                </Style.BannerTitle>
                {
                    this.props.children
                }
            </Style.BannerHeader>
        );
    }
}