import _take from 'lodash/take';
import _map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Search, ShoppingCart, User, X } from 'react-feather';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';
import SearchView from '../../../../0-common/view/SearchView';
import HeaderBaseTemplate from './base/HeaderBaseTemplate';

@inject("storeFactory")
@observer
class HeaderTemplate1 extends HeaderBaseTemplate {

    //@override
    renderMobileHeader(){
        const Style = this.TemplateStyle;

        if(this.isSearchBoxExpanded){
            return (
                <Style.MobileHeaderDiv>
                    <Style.ExpandedSearchBoxSegment basic>
                        <SearchView
                            fluid
                            searchStore={this.SearchStore}
                            filterStore={this.FilterStore}
                            eventContext="" />
                        <X size="40" strokeWidth="1" onClick={this.hideSearchBox} style={{cursor: "pointer"}} />
                    </Style.ExpandedSearchBoxSegment>
                </Style.MobileHeaderDiv>
            );
        } else {
            return (
                <Style.MobileHeaderDiv>
                    <div className="nav-section">
                        {this.renderMobileMenuIcon()}
                    </div>
                    <div className="logo-section">
                    {
                        this.ShopLogo
                        &&
                        <Style.HeaderLogoSegment basic>
                            <Style.HeaderLogo src={this.ShopLogo} onClick={this.onLogoClick}/>
                        </Style.HeaderLogoSegment>
                    }
                    </div>
                    <div className="menu-section">
                        <Style.MobileHeaderMenu secondary>
                            <Menu.Menu position="right">
                                <Style.HeaderMenuItem className="searchMenuItem">
                                    <Search size="25" strokeWidth="1" onClick={this.showSearchBox}/>
                                </Style.HeaderMenuItem>
                                {
                                    this.isAShop
                                    &&
                                    <Style.HeaderMenuItem active={false}>
                                        <ShoppingCart onClick={this.showShoppingCart} strokeWidth="1px"></ShoppingCart>
                                        {
                                            (this.CartCount > 0)
                                            &&
                                            <Style.CartCount color='orange' floating circular>{this.CartCount}</Style.CartCount>
                                        }
                                    </Style.HeaderMenuItem>
                                }
                            </Menu.Menu>
                        </Style.MobileHeaderMenu>
                    </div>
                </Style.MobileHeaderDiv>
            );
        }
    }
    
    //@override
    renderHeader() {
        const Style = this.TemplateStyle;
        const headerPages = _take(this.Pages, 4);
        return (
            <Style.HeaderDiv>
                {
                    this.renderPageMenuIcon()
                }
                {
                    this.ShopLogo
                    &&
                    <Style.HeaderLogoSegment basic>
                        <Style.HeaderLogo src={this.ShopLogo} onClick={this.onLogoClick} />
                    </Style.HeaderLogoSegment>
                }
                <Style.HeaderMenu secondary>
                    <Menu.Menu position="left" className="pageMenu">
                        {
                            _map(headerPages, (page) => {
                                const isPageActive = (this.ActivePageID === page.uid);
                                return(
                                    <Style.HeaderMenuItem
                                        link
                                        key={page.uid}
                                        name={page.Name}
                                        active={isPageActive}
                                        onClick={this.onPageClick.bind(null, page.uid)}>
                                            <Style.HeaderMenuItemName>
                                                {page.Name}
                                            </Style.HeaderMenuItemName>
                                        </Style.HeaderMenuItem>
                                );
                            })
                        }
                    </Menu.Menu>
                    <Menu.Menu position="right">
                        <Style.HeaderMenuItem className="searchMenuItem">
                            <SearchView
                                size="mini"
                                searchStore={this.SearchStore}
                                filterStore={this.FilterStore}
                                eventContext="" />
                        </Style.HeaderMenuItem>
                        {
                            this.isAShop
                            &&
                            <>
                                <Style.HeaderMenuItem>
                                    <Dropdown
                                        item
                                        pointing="top right" 
                                        icon={<User style={{"verticalAlign": "middle"}} />} 
                                        className="userMenuItem">
                                            <Dropdown.Menu>
                                                {
                                                    this.isUserLoggedIn
                                                    &&
                                                    <>
                                                        <Style.HeaderDropdownItem disabled>
                                                            <span>Signed in as <strong>{this.UserDisplayName}</strong></span>
                                                        </Style.HeaderDropdownItem>
                                                        <Dropdown.Divider />
                                                        <Style.HeaderDropdownItem onClick={this.showBuyersProfileView}>
                                                            <Icon name='user outline' />
                                                            <span>Account</span>
                                                        </Style.HeaderDropdownItem>
                                                        <Style.HeaderDropdownItem onClick={this.showBuyersOrderView}>
                                                            <Icon name='box' />
                                                            <span>My Orders</span>
                                                        </Style.HeaderDropdownItem>
                                                        <Style.HeaderDropdownItem onClick={this.showSignOutWarningPopup}>
                                                            <Icon name='power off' />
                                                            <span>Sign Out</span>
                                                        </Style.HeaderDropdownItem>
                                                    </>
                                                }
                                                {
                                                    !this.isUserLoggedIn
                                                    &&
                                                    <Style.HeaderDropdownItem $disableHover>
                                                        <Style.WelcomeSegment basic>
                                                            <Style.WelcomeHeader>
                                                                Welcome
                                                                <Style.WelcomeSubHeader>
                                                                    To access account and manage orders
                                                                </Style.WelcomeSubHeader>
                                                            </Style.WelcomeHeader>
                                                            <Style.HeaderLoginSignUpButton primary content="Login or Signup" onClick={this.showLoginView}/>
                                                        </Style.WelcomeSegment>
                                                    </Style.HeaderDropdownItem>
                                                }
                                                
                                            </Dropdown.Menu>
                                    </Dropdown>
                                </Style.HeaderMenuItem>
                                <Style.HeaderMenuItem active={false}>
                                    <ShoppingCart onClick={this.showShoppingCart}></ShoppingCart>
                                    {
                                        (this.CartCount > 0)
                                        &&
                                        <Style.CartCount color='orange' floating circular>{this.CartCount}</Style.CartCount>
                                    }
                                </Style.HeaderMenuItem>
                            </>
                        }
                    </Menu.Menu>
                </Style.HeaderMenu>
            </Style.HeaderDiv>
        );
    }
}

export default HeaderTemplate1;