import _times from 'lodash/times';
import _map from 'lodash/map';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Container, Grid, Header, Item, Placeholder, Segment } from 'semantic-ui-react';
import SectionBaseTemplate from './base/SectionBaseTemplate';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

@inject("storeFactory")
@observer
class SectionTemplate6 extends SectionBaseTemplate {

    //@Overridden
    get ShouldLoadProductsInCollection(){
		return true;
	}
    
    //@Overridden
    renderLoadingView(isMobileView){
        const Style = this.TemplateStyle;
        return(
            <Container style={{margin: "0"}} fluid>
                <Segment textAlign="center" basic>
                    <Style.CollectionNameHeader textAlign="center">
                        <Placeholder style={{ margin: "auto" }}>
                            <Placeholder.Header>
                                <Placeholder.Line length='very long' />
                            </Placeholder.Header>
                        </Placeholder>
                        <Header.Subheader>
                            <Placeholder style={{ margin: "3rem auto 0" }}>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Header.Subheader>
                    </Style.CollectionNameHeader>
                </Segment>
                <Style.CollectionGrid columns="4" stackable doubling>
                    {
                        _times( (isMobileView ? 4 : 8), counter => {
                            return(
                                <Grid.Column key={counter}>
                                    <Item.Group unstackable>
                                        <Style.ProductItem>
                                            <Item.Image>
                                                <Placeholder style={ isMobileView ? {width: "100%"} : { height: "100%" }}>
                                                    <Placeholder.Image square />
                                                </Placeholder>
                                            </Item.Image>
                                            <Item.Content>
                                                <Placeholder>
                                                    <Placeholder.Header>
                                                        <Placeholder.Line length='very long' />
                                                    </Placeholder.Header>
                                                </Placeholder>
                                                <Placeholder>
                                                    <Placeholder.Paragraph>
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                    </Placeholder.Paragraph>
                                                </Placeholder>
                                                <Placeholder>
                                                    <Placeholder.Header>
                                                        <Placeholder.Line length='medium' />
                                                    </Placeholder.Header>
                                                </Placeholder>
                                            </Item.Content>
                                        </Style.ProductItem>
                                    </Item.Group>
                                </Grid.Column>
                            );
                        })
                    }
                </Style.CollectionGrid>
            </Container>
        );
    }

    //@Overridden
    renderSectionHeader(){
        const Style = this.TemplateStyle;
        let collection = null;
        if (this.Collections.length > 0)
            collection = this.Collections[0];
        const collectionName = collection ? collection.DATA.Name : '';
        const collectionDesc = collection ? parse(collection.DATA.Description_RT || collection.DATA.Description || "") : '';
        return(
            <Segment textAlign="center" basic>
                <Style.CollectionNameHeader textAlign="center">
                    {collectionName}
                    <Header.Subheader className="ql-editor">
                        {collectionDesc}
                    </Header.Subheader>
                </Style.CollectionNameHeader>
            </Segment>
        );
    }

    //@Overridden
    renderMobileView(){
        return this.renderView();
    }

    //@Overridden
    renderView(){
        const Style = this.TemplateStyle;
        return(
            <Container style={{margin: "0"}} fluid>
                <Style.CollectionGrid columns="4" stackable doubling>
                    {
                        _map(this.ProductsInCollection, product => {
                            const productImageURL = this.getProductImage(product);
                            const productID = product.DATA.uid;
                            const productName = product.DATA.Name;
                            const productDescription = parse(product.DATA.Description_RT || product.DATA.Description || "");
                            const productCategory = product.DATA.CategorySourceID;
                            const productCategoryName = product.DATA.CategoryName;
                            const productDiscountedPrice = product.DATA.DiscountedPrice;
                            const productRetailPrice = product.DATA.RetailPrice;

                            const productURL = this.getItemURL(productID, productCategory, "PRODUCT");

                            return(
                                <Grid.Column key={productID}>
                                    <Item.Group>
                                        <Style.ProductItem onClick={this.handleOpenProductView.bind(this, productID, productCategory)}>
                                            <Item.Image size="tiny" src={productImageURL}/>
                                            <Item.Content>
                                                <Style.ProductNameItemHeader as='a' href={productURL}>{productName}</Style.ProductNameItemHeader>
                                                <Style.ProductDescriptionItemMeta className="ql-editor">{productDescription}</Style.ProductDescriptionItemMeta>
                                                {
                                                    productDiscountedPrice
                                                    &&
                                                    <Style.ProductPriceItemMeta>{this.Currency.format(productDiscountedPrice)}</Style.ProductPriceItemMeta>
                                                }
                                            </Item.Content>
                                        </Style.ProductItem>
                                    </Item.Group>
                                </Grid.Column>
                            );
                        })
                    }
                </Style.CollectionGrid>
            </Container>
        );
    }
}

export default SectionTemplate6;