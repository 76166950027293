import classNames from "classnames";
import _find from 'lodash/find';
import { observer } from "mobx-react";
import moment from 'moment';
import React from 'react';
import { Segment } from 'semantic-ui-react';

const HistoryTimelineEvent = observer((props) => {
    const eventType = props.Type;
    const eventEntry = _find(props.data, ['Status', eventType]);
    const isEventPresent = eventEntry ? true : false;
    const eventDate = isEventPresent ? moment(eventEntry.CreationDate).format("ddd, Do MMM") : "";
    const eventClassMap = {
        forEvent : classNames({
            flow : true,
            start : props.Start,
            end : props.End
        }),
        forStage : classNames({
            stageLabel : true,
            done : isEventPresent
        }),
        forDot : classNames({
            dot : true,
            done : isEventPresent
        }),
        forBar : classNames({
            bar : true,
            done : isEventPresent
        }),
        forDate : classNames({
            dateLabel : true,
            done : isEventPresent,
            active : (props.currentStatus === eventType)
        })
    };
    const eventName = props.Name;

    return(
        <div className={eventClassMap.forEvent} >
            <div className={eventClassMap.forStage}>{eventName}</div>
            <div className={eventClassMap.forDot}></div>
            {
                !props.Start
                &&
                <div className={eventClassMap.forBar}></div>
            }
            <div className={eventClassMap.forDate}>{eventDate}</div>
        </div>
    );
})

const OrderDeliveryTimeline = (props) => {
    return(
        <Segment basic className="flowContainer orderDelivered" >
            <HistoryTimelineEvent {...props} Type="orderplaced" Name="Ordered" Start={true} />
            <HistoryTimelineEvent {...props} Type="ordershipped" Name="Shipped" />
            <HistoryTimelineEvent {...props} Type="orderdelivered" Name="Delivered" End={true}/>
        </Segment>
    );
}

const OrderCancelledTimeline = (props) => {
    return(
        <Segment basic className="flowContainer orderCancelled">
            <HistoryTimelineEvent {...props} Type="orderplaced" Name="Ordered" Start={true} />
            <HistoryTimelineEvent {...props} Type="ordercancelled" Name="Cancelled" End={true}/>
        </Segment>
    );
}

const OrderCancelledRefundedTimeline = (props) => {
    return(
        <Segment basic className="flowContainer orderCancelled">
            <HistoryTimelineEvent {...props} Type="orderplaced" Name="Ordered" Start={true} />
            <HistoryTimelineEvent {...props} Type="ordercancelled" Name="Cancelled"/>
            <HistoryTimelineEvent {...props} Type="ordercancelrefunded" Name="Refunded" End={true}/>
        </Segment>
    );
}

const OrderReturnRequestedTimeline = (props) => {
    return(
        <Segment basic className="flowContainer orderReturned">
            <HistoryTimelineEvent {...props} Type="orderplaced" Name="Ordered" Start={true} />
            <HistoryTimelineEvent {...props} Type="orderdelivered" Name="Delivered"/>
            <HistoryTimelineEvent {...props} Type="orderreturnrequested" Name="Return Requested" End={true}/>
        </Segment>
    );
}

const OrderReturnRejectedTimeline = (props) => {
    return(
        <Segment basic className="flowContainer orderReturned">
            <HistoryTimelineEvent {...props} Type="orderplaced" Name="Ordered" Start={true} />
            <HistoryTimelineEvent {...props} Type="orderdelivered" Name="Delivered"/>
            <HistoryTimelineEvent {...props} Type="orderreturnrequested" Name="Return Requested"/>
            <HistoryTimelineEvent {...props} Type="orderreturnrejected" Name="Return Rejected" End={true}/>
        </Segment>
    );
}

const OrderReturnAcceptedTimeline = (props) => {
    return(
        <Segment basic className="flowContainer orderReturned">
            <HistoryTimelineEvent {...props} Type="orderplaced" Name="Ordered" Start={true} />
            <HistoryTimelineEvent {...props} Type="orderdelivered" Name="Delivered"/>
            <HistoryTimelineEvent {...props} Type="orderreturnrequested" Name="Return Requested"/>
            <HistoryTimelineEvent {...props} Type="orderreturnscheduled" Name="Return Scheduled"/>
            <HistoryTimelineEvent {...props} Type="orderreturned" Name="Order Returned"/>
            <HistoryTimelineEvent {...props} Type="orderreturnrefunded" Name="Refund Processed" End={true}/>
        </Segment>
    );
}

function getTimeline(currentStatus){

    switch (currentStatus) {
        case "orderplaced":
        case "ordershipped":
        case "orderdelivered":
            return observer(OrderDeliveryTimeline);
        
        case "ordercancelled":
            return observer(OrderCancelledTimeline);
        case "ordercancelrefunded":
            return observer(OrderCancelledRefundedTimeline);
        
        case "orderreturnrequested":
            return observer(OrderReturnRequestedTimeline);
        case "orderreturnrejected":
            return observer(OrderReturnRejectedTimeline);
        case "orderreturnscheduled":
        case "orderreturned":
        case "orderreturnrefunded":
            return observer(OrderReturnAcceptedTimeline);

        default:
            return null;
    }
}

const ShopOrderHistoryTimelineView = (props) => {

    const TimeLineView = getTimeline(props.currentStatus);
    if(TimeLineView){
        return <TimeLineView {...props} />
    }else{
        return null;
    }
}


export default observer(ShopOrderHistoryTimelineView);