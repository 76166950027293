function loadBaseCSSVariables(props){
    return `
        --button-primary-color: ${props.$rootThemeProp.buttonPrimaryColor};
        --button-primary-bg-color: ${props.$rootThemeProp.buttonPrimaryBackgroundColor};
        --button-primary-border-color : ${props.$rootThemeProp.buttonPrimaryBorderColor};

        --button-primary-color-hover: ${props.$rootThemeProp.buttonPrimaryHoverColor};
        --button-primary-bg-color-hover: ${props.$rootThemeProp.buttonPrimaryHoverBackgroundColor};
        --button-primary-border-color-hover : ${props.$rootThemeProp.buttonPrimaryHoverBorderColor};

        --button-secondary-color: ${props.$rootThemeProp.buttonSecondaryColor};
        --button-secondary-bg-color: ${props.$rootThemeProp.buttonSecondaryBackgroundColor};
        --button-secondary-border-color : ${props.$rootThemeProp.buttonSecondaryBorderColor};

        --button-secondary-color-hover: ${props.$rootThemeProp.buttonSecondaryHoverColor};
        --button-secondary-bg-color-hover: ${props.$rootThemeProp.buttonSecondaryHoverBackgroundColor};
        --button-secondary-border-color-hover : ${props.$rootThemeProp.buttonSecondaryHoverBorderColor};

        --primary-font : ${props.$rootThemeProp.primaryFont};
        --secondary-font : ${props.$rootThemeProp.secondaryFont};

        --primary-color : ${props.$rootThemeProp.primaryColor};
        --secondary-color : ${props.$rootThemeProp.secondaryColor};

        --primary-color-dark : ${props.$rootThemeProp.primaryColorDark};
        --secondary-color-dark : ${props.$rootThemeProp.secondaryColorDark};

        --bg-color : ${props.$rootThemeProp.backgroundColor};

        --padding-top : ${props.$rootThemeProp.documentPaddingTop};
        --padding-right : ${props.$rootThemeProp.documentPaddingRight};
        --padding-bottom : ${props.$rootThemeProp.documentPaddingBottom};
        --padding-left : ${props.$rootThemeProp.documentPaddingLeft};

        --header-height : ${props.$headerThemeProp.height};
        --footer-height : ${props.$footerThemeProp.height};
    `
};

export default loadBaseCSSVariables;