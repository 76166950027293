import { inject } from "mobx-react";

/**
 * HeaderTemplateWrapper is responsible for masking all the call to parent/base HeaderBaseTemplate. 
 * HeaderBaseTemplate is designed with Live Shop.
 * In Shop Configuration View (App Side), HeaderTemplateWrapper is used as a base to render HeaderTemplates.
 * 
 * HeaderTemplateWrapper extends the HeaderTemplate and overrides all the methods as per the preview requirement in Shop Configuration View (App Side)
 * 
 * @param {HeaderTemplate} Template 
 * @returns HOC of HeaderTemplate
 */
export default function HeaderTemplateWrapper(Template){

    @inject("storeFactory")
    class StyledTemplate extends Template.wrappedComponent{

        _templateStyle = null;
        _templateType = "HeaderStyles";

        constructor(props) {
            super(props);
            this.shopStore = props.storeFactory.fetchStore("WEBSHOPS");
            this._templateStyle = this.fetchTemplateStyle();
        }

        fetchTemplateStyle(){
            const themeID = this.props.storeFactory.ShopThemeStore.ThemeID;
            if(themeID){
                const styleMap = this.props.storeFactory.ThemeFactory.getThemeStyleMap(themeID);
                if(styleMap){
                    const templateTypeStyleMap = styleMap[this._templateType];
                    if(templateTypeStyleMap){
                        const templateStyleMap = templateTypeStyleMap[this.props.templateID];
                        if(templateStyleMap){
                            return templateStyleMap;
                        } else {
                            console.warn(`StyledTemplate > 'templateTypeStyleMap[this.props.templateID]' > templateStyleMap not found for templateID : ${this.props.templateID}`);
                            console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for templateID : '${this.props.templateID}', for _templateType : '${this._templateType}', configuration in src/shop/theme/<currentTheme>/style-mapping.js`);
                        }
                    } else {
                        console.warn(`StyledTemplate > 'styleMap[_templateType]' > templateTypeStyleMap not found for _templateType : ${this._templateType}`);
                        console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for _templateType : '${this._templateType}', configuration in src/shop/theme/<currentTheme>/style-mapping.js`);
                    }
                } else {
                    console.warn(`StyledTemplate > 'themeFactory.getThemeStyleMap' > StyleMap not found for theme ID : ${themeID}`);
                    console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for configuration in src/shop/theme/Theme-Library.js`);
                }
            } else {
                console.warn(`StyledTemplate > 'storeFactory.ShopThemeStore.ThemeID' > themeID not found for ${getTemplateDisplayName(Template)}`);
                console.info(` !! QUICK FIX !! : Please check themeID value in storeFactory.ShopThemeStore.ThemeID`);
            }
        }

        get TemplateStyle(){
            return this._templateStyle;
        }

        get isUserLoggedIn(){
            return false;
        }

        get UserDisplayName(){
            return "";
        }

        get CartCount(){
            return 0;
        }

        get isSearchBoxExpanded(){
            return false;
        }

        get SearchStore(){
            this.shopStore.searchStore.isDisabled = true;
            return this.shopStore.searchStore;
        }

        onPageClick = (pageID) => {
            return false;
        }

        showLoginView = () => {
            return false;
        }

        renderMenuIcon(){
            return null;
        }

        //@overridden
        renderMobileHeader(){
            return null;
        }

        render() {
            if(this._templateStyle)//Render the template only if _templateStyle is found/set.
                return super.render();
            else 
                return null;
        }
    }

    StyledTemplate.displayName = `StyledTemplate(${getTemplateDisplayName(Template)})`; /** For better debugging */
    return StyledTemplate;
}

function getTemplateDisplayName(Template) {
    return Template.displayName || Template.name || 'Template';
}
