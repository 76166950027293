import { inject } from "mobx-react";
import { Button, Container, Grid, Icon, Image, Segment } from "semantic-ui-react";
import { ph_emptyImageURL } from '../../../../../0-common/utils/DefaultImage';
import React from "react";
import { emitter, EVENTS } from "../../../../../0-common/utils/EventEmitter";
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';


/**
 * ContentSectionTemplateWrapper is responsible for rendering Designer View  of ContentSectionTemplate . 
 * ContentSectionTemplate-1 is designed for Live Shop.
 * In Shop Design View (App Side), ContentSectionTemplateWrapper is used as a specialized view to render ContentSectionTemplate in design mode.
 * 
 * ContentSectionTemplateWrapper extends the ContentSectionTemplate and overrides 'renderEmptyView' for Shop Design View (App Side)
 * 
 * @param {ContentSectionTemplate} ContentSectionTemplate 
 * @returns HOC of ContentSectionTemplate
 */
export default function ContentSectionTemplateWrapper(ContentSectionTemplate){

    @inject("storeFactory")
    class StyledContentSectionTemplateWrapper extends ContentSectionTemplate.wrappedComponent{

        constructor(props) {
            super(props);
            this.initialize();
        }

        initialize(){
            this._templateType = "SectionStyles";
            this._templateStyle = this.fetchTemplateStyle();
        }

        fetchTemplateStyle(){
            const themeID = this.props.storeFactory.ShopThemeStore.ThemeID;
            if(themeID){
                const styleMap = this.props.storeFactory.ThemeFactory.getThemeStyleMap(themeID);
                if(styleMap){
                    const templateTypeStyleMap = styleMap[this._templateType];
                    if(templateTypeStyleMap){
                        const templateStyleMap = templateTypeStyleMap[this.props.sectionTemplateID];
                        if(templateStyleMap){
                            return templateStyleMap;
                        } else {
                            console.warn(`StyledTemplate > 'templateTypeStyleMap[this.props.sectionTemplateID]' > templateStyleMap not found for templateID : ${this.props.sectionTemplateID}`);
                            console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for templateID : '${this.props.sectionTemplateID}', for _templateType : '${this._templateType}', configuration in src/shop/theme/<currentTheme>/style-mapping.js`);
                        }
                    } else {
                        console.warn(`StyledTemplate > 'styleMap[_templateType]' > templateTypeStyleMap not found for _templateType : ${this._templateType}`);
                        console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for _templateType : '${this._templateType}', configuration in src/shop/theme/<currentTheme>/style-mapping.js`);
                    }
                } else {
                    console.warn(`StyledTemplate > 'themeFactory.getThemeStyleMap' > StyleMap not found for theme ID : ${themeID}`);
                    console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for configuration in src/shop/theme/Theme-Library.js`);
                }
            } else {
                console.warn(`StyledTemplate > 'storeFactory.ShopThemeStore.ThemeID' > themeID not found for  ${getTemplateDisplayName(SectionTemplate)}`);
                console.info(` !! QUICK FIX !! : Please check themeID value in storeFactory.ShopThemeStore.ThemeID`);
            }
        }

        get TemplateStyle(){
            if(!this._templateStyle){
                this.initialize();
            }
            return this._templateStyle;
        }

        handleUploadContentImage = () => {
            const eventContext = "#"+this.SectionID;
            emitter.emit(EVENTS.SHOPDESIGNER.CONTENTSECTION.IMAGE.ADD+eventContext);
        }

        handleEditContentDescription = () => {
            const eventContext = "#"+this.SectionID;
            emitter.emit(EVENTS.SHOPDESIGNER.CONTENTSECTION.DESCRIPTION.EDIT+eventContext);
        }

        //@Overriden
        renderSectionImageView(){
            const Style = this.TemplateStyle;
            if(this.ContentImage){
                return (
                    <Style.ContentImage>
                        <Image src={this.ContentImage} />
                    </Style.ContentImage>
                );
            }else{
                return (
                    <Style.ContentImage>
                        <Image src={ph_emptyImageURL} />
                        <Style.ContentAction onClick={this.handleUploadContentImage}>
                            <Icon size="big" name="camera retro" />
                            <div className="label">Add Image</div>
                        </Style.ContentAction>
                    </Style.ContentImage>
                );
            }
        }
    
        //@Overriden
        renderSectionDescriptionView(){
            const Style = this.TemplateStyle;
            const contentBlendMode = this.ContentBlendMode;
    
            if(this.ContentDescription){
                return (
                    <Style.ContentDescription className="ql-editor" $blendMode={contentBlendMode.source}>
                        {parse(this.ContentDescription)}
                    </Style.ContentDescription>
                );
            }else{
                return (
                    <Style.ContentDescription>
                        <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
                        <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
                        <Style.ContentAction onClick={this.handleEditContentDescription}>
                            <Icon size="big" name="keyboard outline" />
                            <div className="label">Add Content</div>
                        </Style.ContentAction>
                    </Style.ContentDescription>
                );
            }
        }
    }

    StyledContentSectionTemplateWrapper.displayName = `StyledContentSectionTemplateWrapper(${getTemplateDisplayName(ContentSectionTemplate)})`; /** For better debugging */
    return StyledContentSectionTemplateWrapper;
}

function getTemplateDisplayName(Template) {
    return Template.displayName || Template.name || 'TemplateWrapper';
}
