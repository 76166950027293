import _map from 'lodash/map';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import Slider from 'react-slick';
import { Placeholder } from 'semantic-ui-react';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import SectionBaseTemplate from './base/SectionBaseTemplate';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

const arrowSize = "100px";

const SliderNextArrow = function (props) {
    const { className, style, onClick } = props;
    const Style = props.templateStyle;
    return (
        <Style.Slider.NextArrow>
             <Style.Slider.NextArrowText>Next</Style.Slider.NextArrowText>
            <ChevronRight size={arrowSize} strokeWidth="0.25px" onClick={onClick} />
        </Style.Slider.NextArrow>
        
    );
}

const SliderPrevArrow = function (props) {
    const { className, style, onClick } = props;
    const Style = props.templateStyle;
    return (
        <Style.Slider.PrevArrow>
            <ChevronLeft size={arrowSize} strokeWidth="0.25px" onClick={onClick} />
            <Style.Slider.PrevArrowText>Prev</Style.Slider.PrevArrowText>
        </Style.Slider.PrevArrow>
    );
}

@inject("storeFactory")
@observer
class SectionTemplate2 extends SectionBaseTemplate {

    constructor(props) {
        super(props);
        this.carousalSettings = {
            fade: true,
            dots: false,
            infinite: true,
            pauseOnHover: true,
            speed: 1500,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SliderNextArrow templateStyle={this.TemplateStyle} />,
            prevArrow: <SliderPrevArrow templateStyle={this.TemplateStyle} />,
            beforeChange: this.onSliderChangeHandler.bind(this)
        };
    }

    @observable activeSlideIndex = 0;

    @computed
    get ActiveSlide(){
        return this.activeSlideIndex;
    }

    set ActiveSlide($currentIndex){
        this.activeSlideIndex = $currentIndex;
    }

    @action
    onSliderChangeHandler(current, next){
        this.ActiveSlide = next;
    }

    //@Overridden
    renderLoadingView(){
        const Style = this.TemplateStyle;
        return (
            <Slider {...this.carousalSettings}>
                <Style.Slider.SliderDiv>
                    <Style.ImagePlaceHolder style={{height: "100%"}}>
                        <Placeholder.Image square />
                    </Style.ImagePlaceHolder>
                    <Style.CollectionInfoPlaceHolder basic padded='very' $leftMargin={arrowSize} $slideIndex={0} $currentIndex={0}>
                        <Style.CollectionName className="name" $slideIndex={0} $currentIndex={0} $animate={false}>
                            <Style.InfoPlaceHolder>
                                <Placeholder.Header>
                                    <Placeholder.Line length='very long' />
                                </Placeholder.Header>
                            </Style.InfoPlaceHolder>
                        </Style.CollectionName>
                        <Style.CollectionDesc className="description" $slideIndex={0} $currentIndex={0} $animate={false}>
                            <Style.InfoPlaceHolder>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Style.InfoPlaceHolder>
                        </Style.CollectionDesc>
                        <Style.ViewMoreButton content="VIEW MORE" $slideIndex={0} $currentIndex={0} $animate={false} disabled={true} />
                    </Style.CollectionInfoPlaceHolder>
                </Style.Slider.SliderDiv>
            </Slider>
        );
    }

    //@Overridden
    renderSectionHeader(){
        return null;
    }

    //@Overridden
    renderMobileView(){
        return this.renderView(true);
    }

    //@Overridden
    renderView(isMobileView){
        if(isMobileView)
            this.carousalSettings.dots = true;
        else
            this.carousalSettings.dots = false;

        const collections = this.Collections;
        const Style = this.TemplateStyle;
        return(
            <Slider {...this.carousalSettings}>
                {
                    _map(collections, (collection, index) => {
                        const firstSoloImageURL = this.getCollectionProfileImage(collection);
                        return(
                            <Style.Slider.SliderDiv $bgImageURL={firstSoloImageURL} key={collection.DATA.uid}>
                                <Style.CollectionInfoSegment basic padded='very' $leftMargin={arrowSize} $slideIndex={index} $currentIndex={this.ActiveSlide}>
                                    <Style.CollectionName className="name" $slideIndex={index} $currentIndex={this.ActiveSlide} $animate={true}>
                                        {collection.DATA.Name}
                                    </Style.CollectionName>
                                    <Style.CollectionDesc className="ql-editor description" $slideIndex={index} $currentIndex={this.ActiveSlide} $animate={true}>
                                        {parse(collection.DATA.Description_RT || collection.DATA.Description || "")}
                                    </Style.CollectionDesc>
                                    <Style.ViewMoreButton content="VIEW MORE" $slideIndex={index} $currentIndex={this.ActiveSlide} $animate={true} onClick={this.handleOpenCollectionView.bind(this, collection.DATA.uid, collection.DATA.Category)} />
                                </Style.CollectionInfoSegment>
                            </Style.Slider.SliderDiv>
                        );
                    })
                }
            </Slider>
        );
    }
}

export default SectionTemplate2;