import _forEach from 'lodash/forEach';
import _forIn from "lodash/forIn";
import FooterTemplateWrapper from "../template/footer/footer-template/preview-wrapper/FooterTemplateWrapper";
import HeaderTemplateWrapper from "../template/header/header-template/preview-wrapper/HeaderTemplateWrapper";
import ContentSectionTemplateWrapper from "../template/home/content-section-template/design-wrapper/ContentSectionTemplateWrapper";
import SectionTemplateWrapper from "../template/home/section-template/preview-wrapper/SectionTemplateWrapper";
import TemplateLibrary from "../template/Template-Library";
import { infuseStyle } from "./DecoratorUtil";

class ShopTemplateFactory {

    _styledTemplateRepository = {};
    _wrappedTemplateRepository = {};

    constructor(){
        this._InitializeFactory();
    }

    _InitializeFactory(){
        
        _forIn(TemplateLibrary.FooterTemplateLibrary, (template, templateID) => {
            this._styledTemplateRepository[templateID] = infuseStyle(template, "FooterStyles", templateID) ;
            this._wrappedTemplateRepository[templateID] = FooterTemplateWrapper(template);
        });

        _forEach(TemplateLibrary.HeaderTemplateLibrary, (template, templateID) => {
            this._styledTemplateRepository[templateID] = infuseStyle(template, "HeaderStyles", templateID);
            this._wrappedTemplateRepository[templateID] = HeaderTemplateWrapper(template);
        });

        _forEach(TemplateLibrary.SectionTemplateLibrary, (template, templateID) => {
            this._styledTemplateRepository[templateID] = infuseStyle(template, "SectionStyles", templateID);
            this._wrappedTemplateRepository[templateID] = SectionTemplateWrapper(template);
        });

        _forEach(TemplateLibrary.ContentSectionTemplateLibrary, (template, templateID) => {
            this._styledTemplateRepository[templateID] = infuseStyle(template, "SectionStyles", templateID);
            this._wrappedTemplateRepository[templateID] = ContentSectionTemplateWrapper(template);
        });

        _forEach(TemplateLibrary.ProductTemplateLibrary, (template, templateID) => {
            this._styledTemplateRepository[templateID] = infuseStyle(template, "ProductStyles", templateID);
        });

        _forEach(TemplateLibrary.BannerTemplateLibrary, (template, templateID) => {
            this._styledTemplateRepository[templateID] = infuseStyle(template, "BannerStyles", templateID);
        });
    };

    getTemplate(templateID){
        const template = this._styledTemplateRepository[templateID];
        if(template){
            return template;
        } else {
            console.warn(`ShopTemplateFactory > 'this._templateRepository[templateID]' > template not found for templateID : ${templateID}`);
            console.info(` !! QUICK FIX !! : Please check your template library with templateID : '${templateID}' for configuration in src/shop/template/Template-Library.js`);
        }
    };

    getTemplateWrapper(templateID){
        const wrappedTemplate = this._wrappedTemplateRepository[templateID];
        if(wrappedTemplate){
            return wrappedTemplate;
        } else {
            console.warn(`ShopTemplateFactory > 'this._wrappedTemplateRepository[templateID]' > wrapped template not found for templateID : ${templateID}`);
            console.info(` !! QUICK FIX !! : Please check your template library with templateID : '${templateID}' for configuration in src/shop/template/Template-Library.js`);
        }
    }

    getHeaderTemplates(){
        return TemplateLibrary.HeaderTemplateLibrary;
    }

    getFooterTemplates(){
        return TemplateLibrary.FooterTemplateLibrary;
    }

    getSectionTemplates(){
        return TemplateLibrary.SectionTemplateLibrary;
    }

    getContentSectionTemplates(){
        return TemplateLibrary.ContentSectionTemplateLibrary;
    }


};

export default new ShopTemplateFactory();
