import parse from 'html-react-parser';
import { inject, observer } from "mobx-react";
import React from 'react';
import 'react-quill/dist/quill.snow.css';
import { Grid, Image } from "semantic-ui-react";
import ContentSectionBaseTemplate from "./base/ContentSectionBaseTemplate";


@inject("storeFactory")
@observer
export default class ContentSectionTemplate1 extends ContentSectionBaseTemplate {

    //@Overriden
    renderSectionImageView(){
        const Style = this.TemplateStyle;
        if(this.ContentImage){
            return (
                <Style.ContentImage>
                    <Image src={this.ContentImage} />
                </Style.ContentImage>
            );
        }else{
            return null;
        }
    }

    //@Overriden
    renderSectionDescriptionView(){
        const Style = this.TemplateStyle;
        const contentBlendMode = this.ContentBlendMode;

        if(this.ContentDescription){
            return (
                <Style.ContentDescription className="ql-editor" $blendMode={contentBlendMode.source}>
                    {parse(this.ContentDescription)}
                </Style.ContentDescription>
            );
        }else{
            return null;
        }
    }

    renderMobileView(){
        return this.renderView();
    }

    //@Overriden
    renderView(){
        const Style = this.TemplateStyle;
        const SectionImageView = this.renderSectionImageView();
        const SectionDescriptionView = this.renderSectionDescriptionView();
        if(SectionImageView || SectionDescriptionView){
            return (
                <Style.ContentGrid stackable relaxed='very' columns={2} $flowDirection={this.ContentFlow.source} $blendMode={this.ContentBlendMode.source} >
                    <Grid.Column stretched width={6}>
                        {SectionImageView}
                    </Grid.Column>
                    <Grid.Column stretched width={10}>
                        {SectionDescriptionView}
                    </Grid.Column>
                </Style.ContentGrid>
            );
        }else{
            return null;
        }
        
    }
}