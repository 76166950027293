import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import loadBaseCSSVariables from "./Theme-CSS-Variables";

function getTemplateDisplayName(Template, templateID) {
    return Template.displayName || Template.name || templateID;
}

function getComponentDisplayName(Component, componentName) {
    return Component.displayName || Component.name || componentName;
}

function getRootComponentDisplayName(RootComponent) {
    return RootComponent.displayName || RootComponent.name || 'RootComponent';
}

export function infuseStyle(Template, templateType, templateID){

    class StyledTemplate extends React.Component{

        _templateStyle = null;

        constructor(props) {
            super(props);
            this._templateStyle = this.fetchTemplateStyle();
        }

        fetchTemplateStyle(){
            const themeID = this.props.storeFactory.ShopThemeStore.ThemeID;
            if(themeID){
                const styleMap = this.props.storeFactory.ThemeFactory.getThemeStyleMap(themeID);
                if(styleMap){
                    const templateTypeStyleMap = styleMap[templateType];
                    if(templateTypeStyleMap){
                        const templateStyleMap = templateTypeStyleMap[templateID];
                        if(templateStyleMap){
                            return templateStyleMap;
                        } else {
                            console.warn(`StyledTemplate > 'templateTypeStyleMap[templateID]' > templateStyleMap not found for templateID : ${templateID}`);
                            console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for templateID : '${templateID}', for templateType : '${templateType}', configuration in src/shop/theme/<currentTheme>/style-mapping.js`);
                        }
                    } else {
                        console.warn(`StyledTemplate > 'styleMap[templateType]' > templateTypeStyleMap not found for templateType : ${templateType}`);
                        console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for templateType : '${templateType}', configuration in src/shop/theme/<currentTheme>/style-mapping.js`);
                    }
                } else {
                    console.warn(`StyledTemplate > 'storeFactory.ThemeFactory.getThemeStyleMap' > StyleMap not found for theme ID : ${themeID}`);
                    console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for configuration in src/shop/theme/Theme-Library.js`);
                }
            } else {
                console.warn(`StyledTemplate > 'storeFactory.ShopThemeStore.ThemeID' > themeID not found for ${getTemplateDisplayName(Template, templateID)}`);
                console.info(` !! QUICK FIX !! : Please check if 'storeFactory.ShopThemeStore' returns themeID`);
            }
        }

        render() {
            if(this._templateStyle)//Render the template only if _templateStyle is found/set.
                return <Template templateStyle={this._templateStyle} {...this.props} templateType="TEMPLATE" />
            else 
                return null;
        }
    }

    StyledTemplate.displayName = `StyledTemplate(${getTemplateDisplayName(Template, templateID)})`; /** For better debugging */
    return StyledTemplate;
}

export function injectStyle(moduleName, componentName){

    return function(ViewComponent){
        class StyledViewComponent extends React.Component{

            _componentStyle = null;
    
            constructor(props) {
                super(props);
                this._componentStyle = this.fetchComponentStyle();
            }
    
            fetchComponentStyle(){
                const themeID = this.props.storeFactory.ShopThemeStore.ThemeID;
                if(themeID){
                    const styleMap = this.props.storeFactory.ThemeFactory.getThemeStyleMap(themeID);
                    if(styleMap){
                        const moduleStyleMap = styleMap[moduleName];
                        if(moduleStyleMap){
                            const componentStyleMap = moduleStyleMap[componentName];
                            if(componentStyleMap){
                                return componentStyleMap;
                            } else {
                                console.error(`StyledViewComponent > 'moduleStyleMap[componentName]' > componentStyleMap not found for componentName : ${componentName}`);
                                console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for componentName : '${componentName}', for moduleName : '${moduleName}', configuration in src/shop/theme/<currentTheme>/style-mapping.js`);
                                throw new Error("!! Component Style Not Found !!");
                            }
                        } else {
                            console.error(`StyledViewComponent > 'styleMap[moduleName]' > moduleStyleMap not found for moduleName : ${moduleName}`);
                            console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for moduleName : '${moduleName}' configuration in src/shop/theme/<currentTheme>/style-mapping.js`);
                            throw new Error("!! Component Style Not Found !!");
                        }
                    } else {
                        console.error(`StyledViewComponent > 'storeFactory.ThemeFactory.getThemeStyleMap' > StyleMap not found for theme ID : ${themeID}`);
                        console.info(` !! QUICK FIX !! : Please check your theme with themeID : '${themeID}' for configuration in src/shop/theme/Theme-Library.js`);
                        throw new Error("!! Component Style Not Found !!");
                    }
                } else {
                    console.warn(`StyledViewComponent > 'storeFactory.ShopThemeStore.ThemeID' > themeID not found for ${getComponentDisplayName(ViewComponent, componentName)}`);
                    console.info(` !! QUICK FIX !! : Please check if 'storeFactory.ShopThemeStore' returns themeID`);
                    throw new Error("!! Theme Not Found !!");
                }
            }
    
            render() {
                return <ViewComponent componentStyle={this._componentStyle} {...this.props} />
            }
        }
    
        StyledViewComponent.displayName = `StyledViewComponent(${getComponentDisplayName(ViewComponent, componentName)})`; /** For better debugging */
        return StyledViewComponent;
    }
}

export function infuseRootStyle(RootComponent, ParentComponentName){

    const StyledComponent = styled(RootComponent)`
        ${props => loadBaseCSSVariables(props)}
    `;
    class RootStyledComponent extends React.Component{
    
        constructor(props) {
            super(props);
        }

        componentDidMount(){
            console.log("M. "+RootStyledComponent.displayName);
        }

        render() {
            const currentThemeID = this.props.$storeFactory.ShopThemeStore.ThemeID;
            const currentThemeInfo = this.props.$storeFactory.ThemeFactory.getThemeInfo(currentThemeID);
            if(!currentThemeInfo)
                return null;

            const rootTheme = currentThemeInfo.RootTheme;
            const headerTheme = currentThemeInfo.HeaderTheme;
            const footerTheme = currentThemeInfo.FooterTheme;
           
            const GlobalStyle = this.props.$storeFactory.ThemeFactory.getThemeGlobalStyle(currentThemeID);
            const FontStyle = this.props.$storeFactory.ThemeFactory.getThemeFontStyle(currentThemeID);

            return(
                <>
                    {
                        this.props.$injectGlobalStyle 
                        &&
                        GlobalStyle
                        &&
                        <GlobalStyle />
                    }
                    {
                        this.props.$injectFontStyle
                        &&
                        FontStyle
                        &&
                        <FontStyle />
                    }
                    <StyledComponent {...this.props} className={this.props.className} $rootThemeProp={rootTheme} $headerThemeProp={headerTheme} $footerThemeProp={footerTheme} />
                </>
            );
        }
    }

    RootStyledComponent.displayName = `RootStyledComponent(${getRootComponentDisplayName(RootComponent)}) - ${ParentComponentName}`; /** For better debugging */
    return observer(RootStyledComponent);
}

export function infuseRootStyleWithTheme(RootComponent, themeID){

    const StyledComponent = styled(RootComponent)`
        ${props => loadBaseCSSVariables(props)}
    `;
    class RootStyledComponent extends React.Component{
    
        constructor(props) {
            super(props);
        }

        componentDidMount(){
            console.log("M. "+RootStyledComponent.displayName);
        }

        render() {
            const currentThemeID = themeID;
            const currentThemeInfo = this.props.$storeFactory.ThemeFactory.getThemeInfo(currentThemeID);
            if(!currentThemeInfo)
                return null;

            const rootTheme = currentThemeInfo.RootTheme;
            const headerTheme = currentThemeInfo.HeaderTheme;
            const footerTheme = currentThemeInfo.FooterTheme;
           
            const GlobalStyle = this.props.$storeFactory.ThemeFactory.getThemeGlobalStyle(currentThemeID);
            const FontStyle = this.props.$storeFactory.ThemeFactory.getThemeFontStyle(currentThemeID);

            return(
                <>
                    {
                        this.props.$injectGlobalStyle 
                        &&
                        GlobalStyle
                        &&
                        <GlobalStyle />
                    }
                    {
                        this.props.$injectFontStyle
                        &&
                        FontStyle
                        &&
                        <FontStyle />
                    }
                    <StyledComponent {...this.props} className={this.props.className} $rootThemeProp={rootTheme} $headerThemeProp={headerTheme} $footerThemeProp={footerTheme} />
                </>
            ) 
                
        }
    }

    RootStyledComponent.displayName = `RootStyledComponent(${getRootComponentDisplayName(RootComponent)})`; /** For better debugging */
    return observer(RootStyledComponent);
}